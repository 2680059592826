@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.circle {
  cursor: pointer;
  fill: $sds-color-secondary-400;
  fill-opacity: 0.85;

  &.hoverable {
    &:hover {
      fill: $sds-color-primary-500;
      fill-opacity: 1;
    }
  }

  &.active {
    fill: $sds-color-primary-500;
    fill-opacity: 1;
  }
}
