@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.navWrapper {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navTitle {
  @include font-header-xs;
  color: $sds-color-gray-500;
  margin-bottom: $sds-spaces-xl;
  min-width: 110px;
}

.navLinksContainer {
  display: block;
  border-left: 2px solid $sds-color-gray-200;
}
