@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.content {
  padding: 30px 20px;
  position: relative;
}

.title,
.loadingMsg {
  @include font-header-xl;
  margin: 0 $sds-spaces-m;
  margin-right: 60px;
  word-break: break-all;
}

.geneContents {
  padding: 0 $sds-spaces-m;

  .subtitle {
    @include font-header-m;
    padding-top: $sds-spaces-xl;
    padding-bottom: $sds-spaces-s;
  }

  .linksSection {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .linksList {
    min-width: 200px;
    float: left;
    list-style: none;
    padding-inline-start: 0px;
    list-style: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .link {
    color: $sds-color-primary-400;
    cursor: pointer;
  }
}

.cardFooter {
  @include font-body-xxs;
  padding-top: $sds-spaces-l;
  color: $sds-color-gray-500;

  .cardLink {
    color: $sds-color-gray-black;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .disclaimer {
    padding-top: $sds-spaces-xs;
  }
}
