@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.titleSection {
  padding-bottom: $sds-spaces-m;
  @include font-header-s;
}

.main {
  padding-bottom: $sds-spaces-xl;
}

.label {
  @include font-body-xs;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:not(:last-child) {
    padding-bottom: $sds-spaces-l;
  }

  // Clear SDS InputRadio top/bottom/left padding
  :global(.MuiButtonBase-root.MuiRadio-root) {
    padding: 0 9px 0 0 !important;
  }

  .boldText {
    @include font-header-xs;
  }
}
