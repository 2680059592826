@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.projectSettingsModal {
  max-height: 80vh;
}

.projectSettingsContent {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    @include font-header-xl;
    font-weight: $font-weight-regular;

    .highlight {
      font-weight: $font-weight-semibold;
    }
  }

  .background {
    background-color: $sds-color-gray-100;
    border-radius: $sds-corners-corner-m;
  }

  .projectVisibility {
    margin-top: $sds-spaces-xl;
    margin-bottom: $sds-spaces-xl;

    .visibility {
      display: flex;
      align-items: center;
      position: relative;
      padding: $sds-spaces-l;

      .icon {
        flex-shrink: 0;
        align-self: flex-start;
      }

      .text {
        flex-grow: 100;
        margin-left: $sds-spaces-l;

        .label {
          @include font-header-s;
          color: black;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .toggle {
            @include font-caps-xxxs;
            color: $sds-color-primary-400;
            cursor: pointer;
          }
        }

        .note {
          @include font-body-xs;
          margin-top: $sds-spaces-xxs;
          color: $sds-color-gray-500;
        }
      }
    }
  }

  .formContainer {
    margin-top: $sds-spaces-xl;
    flex: 1;
    display: flex;
  }

  .formContainer:not(:last-child) {
    margin-bottom: $sds-spaces-xl;
  }
}
