@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.accordionContainer {
  border-bottom: none;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 $sds-spaces-xl;
    width: fit-content;
    border-radius: 5px;
  }
}
