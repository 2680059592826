@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.resultsContainer {
  color: $sds-color-gray-black;

  .section {
    margin-bottom: 45px;

    .title {
      @include font-header-l;
      margin-bottom: $sds-spaces-l;

      .helpIcon {
        display: inline;
        margin-left: $sds-spaces-xxs;

        &.lower {
          vertical-align: -1px;
        }

        svg:hover {
          fill: $sds-color-primary-400;
        }
      }
    }

    .subheader {
      @include font-header-s;
    }

    .body {
      @include font-body-s;
      margin-bottom: $sds-spaces-l;
    }

    .offsetBody {
      @include font-body-s;
      margin-bottom: $sds-spaces-l;
      margin-left: $sds-spaces-xl;

      .emphasis {
        font-weight: $font-weight-semibold;
      }
    }

    .helpIcon {
      margin-bottom: $sds-spaces-xxs;
      margin-left: 5px;
      height: 14px;
    }

    .metricsTable {
      height: 110px;
      padding: $sds-spaces-xl;

      .tableHeader {
        border-bottom: $sds-borders-gray-200;

        .tableHeaderRow {
          border-bottom: $sds-borders-gray-200;
        }

        .tableHeaderLabel {
          @include font-header-xs;
          vertical-align: inherit;
        }
      }

      .tableGrid {
        overflow: hidden !important;
        outline: none;
      }

      .tableRow {
        border-bottom: 0;

        // Override base_table.scss
        &:global(.ReactVirtualized__Table__headerRow) {
          border-bottom: $sds-borders-gray-200;
        }

        &:hover {
          background-color: inherit;
        }

        .cell {
          @include font-header-s;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .taxonName {
          text-align: left;
        }
      }

      .primaryHeader {
        text-align: left;
      }
    }
  }

  .coverageContainer {
    height: 370px;
    padding: $sds-spaces-xl;

    .metrics {
      display: flex;
      margin-bottom: $sds-spaces-m;

      .column {
        flex: 1 1 0;
        min-width: 0;
        margin-right: $sds-spaces-m;

        &:last-child {
          margin-right: 0;
        }
      }

      .metric {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 $sds-spaces-m;

        &:first-child {
          background-color: $sds-color-gray-100;
        }

        .value {
          @include font-body-xxs;
          display: flex;
          justify-content: center;
          flex: 1 1 auto;
          min-width: 0;
          cursor: default;
        }

        .label {
          @include font-header-xxs;
        }
      }
    }

    .coverageVizHistogram {
      height: 285px;
      background-color: $sds-color-gray-100;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
    }
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin: $sds-spaces-xs 0 $sds-spaces-xl 0;

  .dropdownContainer {
    width: 340px;
  }

  .learnMoreLink {
    @include font-header-xxs;

    &.alignRight {
      margin-left: auto;
    }
  }

  &.removeBottomMargin {
    margin-bottom: 0 !important;
  }
}

.hoverTooltip {
  position: fixed;
  background-color: $sds-color-gray-white;
  z-index: 100;
  // So the tooltip appears on the top-right instead of bottom-right of mouse.
  transform: translateY(-100%);
}

.raisedContainer {
  border-radius: $sds-corners-corner-m;
  border: $sds-borders-gray-200;
  box-shadow: $sds-drop-shadows-shadow-s;
}

.downloadLink {
  margin-left: $sds-spaces-xxs;
  margin-top: $sds-spaces-xxxs;
}

.customReference {
  &:hover {
    color: $sds-color-primary-400;
  }
}
