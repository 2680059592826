@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.dropdownTrigger {
  border: $sds-borders-gray-300;
  border-radius: $sds-corners-corner-m;
  background-color: $sds-color-gray-white;
  color: $sds-color-gray-black;
  letter-spacing: 0.4px;
  height: 34px;
  line-height: 24px;
  padding: $sds-spaces-xxs 26px $sds-spaces-xxs $sds-spaces-l;
  font-size: 13px;
  overflow: hidden;

  &.rounded {
    border-radius: $sds-corners-corner-l;
  }

  &.active {
    border: $sds-borders-primary-400;
  }

  &.disabled {
    border: $sds-borders-gray-300;
    color: $sds-color-gray-500;
    opacity: 1 !important;
  }

  .labelContainer {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .label {
    font-weight: 600;

    &:not(.disableMarginRight) {
      margin-right: 5px;
    }
  }

  .itemSubtext {
    @include font-body-xxs;
    color: $sds-color-gray-500;
    margin-left: $sds-spaces-xs;
  }

  .placeholder {
    color: $sds-color-gray-300;
  }

  &.erred {
    border: $sds-borders-error-400;

    .placeholder {
      color: $sds-color-error-400;
    }
  }
}

.hide {
  display: none;
}
