@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.downloadCell {
  display: flex;
  text-align: left;

  .downloadIcon {
    margin-right: $sds-spaces-l;
    fill: $sds-color-gray-300;
  }

  .downloadRightPane {
    .downloadNameContainer {
      display: flex;
      align-items: center;
    }

    .downloadName {
      @include font-header-s;
    }

    .downloadStatus {
      margin-left: $sds-spaces-xs;
    }

    .metadata {
      @include font-body-xxs;
      color: $sds-color-gray-500;

      .detailsLink {
        cursor: pointer;
        margin-right: $sds-spaces-xxs;

        &:hover {
          color: $sds-color-gray-black;
        }
      }

      .userName {
        margin-left: $sds-spaces-xxs;
      }
    }
  }
}

.samplesCell {
  @include font-body-xxs;
  font-weight: $font-weight-regular;
}

.statusCell {
  margin-bottom: $sds-spaces-xxxs;

  .messageContainer,
  .links {
    display: flex;
    align-items: center;
  }

  .messageContainer {
    margin-bottom: $sds-spaces-s;
  }

  .icon {
    width: 16px;
    margin-right: $sds-spaces-xs;
    fill: $sds-color-success-400;

    &.warning {
      fill: $sds-color-warning-400;
    }

    &.error {
      fill: $sds-color-error-400;
      margin-bottom: 1px;
    }

    &.loading {
      color: $sds-color-primary-400;
      margin-right: $sds-spaces-xxs;
    }
  }

  .separator {
    @include font-body-xxs;
    margin: 0 $sds-spaces-s;
    color: $sds-color-gray-500;
  }

  .link {
    @include font-caps-xxxs;
    /* overwrite header.scss */
    color: $sds-color-primary-400 !important;
    cursor: pointer;
  }
}
