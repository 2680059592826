@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $sds-spaces-m;

  .filtersTrigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    margin-right: 25px;

    .filtersCounter {
      background-color: $sds-color-gray-200;
      color: $sds-color-primary-400;
      font-size: 11px;
      min-height: 18px;
      min-width: 18px;
      // Override semantic-ui
      padding: 3px !important;
      position: absolute;
      right: calc(-1 * $sds-spaces-xs);
      vertical-align: middle;
    }
  }

  .filterButtonContainer {
    margin-right: 25px;
  }

  .blankFill {
    flex: 1 0 auto;
  }

  .statsTrigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    margin-left: 25px;
  }

  .tabs {
    flex: 1 0 auto;
    margin-top: $sds-spaces-m;
  }

  .searchContainer {
    margin-right: 25px;
  }
}

.disabled {
  cursor: default !important;
}

.popupText {
  @include font-body-xxs;

  .popupSubtitle {
    color: $sds-color-gray-500;
  }
}
