@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.css");
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.mapContainer {
  display: block;
  position: relative;
  flex: 1 0 auto;
  flex-direction: column;

  .zoomControl {
    position: absolute;
    bottom: 50px;
    right: 15px;
    box-shadow: $sds-drop-shadows-shadow-m;

    :global(.mapboxgl-ctrl-icon) {
      width: 25px;
      height: 25px;

      &:focus {
        // Override materialize.css
        background-color: transparent !important;
      }
    }
  }

  :global(.mapboxgl-ctrl-attrib) {
    right: 5px;
  }
}
