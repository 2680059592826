@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.item {
  text-align: left;
  display: flex;

  .visibility {
    display: flex;
    flex: 0 0 40px;
    align-items: flex-start;
    justify-items: center;
    margin-right: 12px;
  }

  .itemName {
    @include font-header-m;
    color: $sds-color-gray-black;
    margin-bottom: $sds-spaces-xxxs;
  }

  .itemDescription {
    @include font-body-s;
    color: $sds-color-gray-500;
    font-weight: $font-weight-regular;
    line-height: 20px;
    margin: 0 0 $sds-spaces-s 0;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .itemDetails {
    @include font-body-xxs;

    color: $sds-color-gray-500;
    font-weight: $font-weight-regular;

    span:not(:last-child) {
      margin-right: $sds-spaces-s;
    }

    span:not(:first-child) {
      margin-left: $sds-spaces-s;
    }
  }
}

.dateContainer {
  .elapsed {
    @include font-body-xxs;

    font-weight: $font-weight-regular;
  }
}

.itemHeader {
  text-align: left;
}

.icon {
  fill: $sds-color-primary-400;
  height: 100%;
  justify-content: center;
  width: $icon-xl;
}

.iconFlask svg {
  color: $sds-color-gray-300;
}

.numberValueAndPercentage {
  .percentage {
    @include font-body-xxs;
    font-weight: $font-weight-regular;
  }
}

.visualization {
  text-align: left;
  display: flex;

  .visibility {
    flex: 0 0 40px;
    display: flex;
    align-items: flex-start;
    justify-items: center;
    margin-right: 12px;
  }

  .vizRightPane {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;

    .vizNameAndStatus {
      display: flex;
      align-items: center;

      .vizName {
        @include font-header-m;
        color: $sds-color-gray-black;
        margin-bottom: $sds-spaces-xxxs;

        min-width: 0;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .vizStatus {
        margin-left: $sds-spaces-xs;
      }
    }
  }
  .vizDetails {
    @include font-body-xxs;
    color: $sds-color-gray-500;
    font-weight: $font-weight-regular;
  }
}

.sample {
  display: flex;

  .visibility {
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-items: center;
    margin-right: 12px;

    .icon {
      fill: $sds-color-primary-400;
      width: 36px;
    }
  }

  .sampleRightPane {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;

    .sampleNameAndStatus {
      display: flex;
      align-items: center;
      margin-bottom: 1px;

      .sampleName {
        @include font-header-s;

        min-width: 0;
        text-align: left;
        color: $sds-color-gray-black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .sampleStatus {
        margin-left: $sds-spaces-xs;
      }
    }

    .sampleDetails {
      @include font-body-xxs;

      font-weight: $font-weight-regular;
      color: $sds-color-gray-500;
      text-align: left;

      span:not(:first-child) {
        margin-left: $sds-spaces-xxs;
      }

      span:not(:last-child) {
        margin-right: $sds-spaces-xxs;
      }
    }

    .consensusGenomeCount {
      @include font-body-xxxs;
      color: $sds-color-primary-400;
    }
  }
}

.counts {
  display: flex;
  align-items: center;
  flex-direction: column;

  .sampleCount {
    @include font-header-xs;
  }

  .analysesCounts {
    @include font-body-xxxs;
    margin-top: $sds-spaces-xxxs;
    color: $sds-color-gray-500;
  }
}

.popup {
  @include font-body-xs;

  .header {
    font-weight: $font-weight-semibold;
  }

  .list {
    margin-top: 0px;
    margin-left: 18px;

    .unorderedListItem {
      margin-bottom: 0px !important;
    }
  }

  .topWr {
    display: flex;
  }

  .accessionId {
    text-decoration: underline;
    margin-right: $sds-spaces-xxs;
  }
}

.referenceAccession {
  .title {
    @include font-header-s;

    min-width: 0;
    text-align: left;
    color: $sds-color-gray-black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .details {
    @include font-body-xxs;

    font-weight: $font-weight-regular;
    color: $sds-color-gray-500;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .missingAccessionContent {
    text-align: center;
  }
}

.referenceAccessionTooltip {
  .title {
    @include font-header-xs;
    color: $sds-color-gray-white;
  }

  .details {
    @include font-header-xxs;
    font-weight: $font-weight-regular;
    color: $sds-color-gray-400;
  }
}

.loading {
  .base {
    @include loadingAnimation;

    background-clip: content-box;
    padding: $sds-spaces-xxs 0;
    height: 20px;
  }

  .numberValueAndPercentage {
    .value {
      @include loadingAnimation;

      background-clip: content-box;
      height: 16px;
      padding: $sds-spaces-xxxs 15%;
    }

    .percentage {
      @include loadingAnimation;

      background-clip: content-box;
      height: 15px;
      padding: $sds-spaces-xxxs 20%;
    }
  }

  .visibility {
    @include loadingAnimation;

    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-items: center;
    margin-right: 12px;

    .icon {
      fill: $sds-color-primary-400;
      width: 36px;
    }
  }

  .sampleRightPane {
    .sampleNameAndStatus {
      @include loadingAnimation;

      background-clip: content-box;
      padding: $sds-spaces-xxs 0;
      height: 20px;
      width: 365px;
    }

    .sampleDetails {
      @include loadingAnimation;

      background-clip: content-box;
      padding: $sds-spaces-xxs 0;
      height: 17px;
      width: 315px;
    }
  }

  .itemRightPane {
    flex: 1 0 auto;

    .itemName {
      @include loadingAnimation;

      background-clip: content-box;
      padding: $sds-spaces-xxs 0;
      height: 20px;
      flex: 1 0 auto;
    }

    .itemDetails {
      @include loadingAnimation;

      background-clip: content-box;
      padding: $sds-spaces-xxs 0;
      height: 17px;
      flex: 1 0 auto;
    }
  }

  .dateContainer {
    .elapsed {
      @include loadingAnimation;

      background-clip: content-box;
      height: 12px;
      margin: $sds-spaces-xxs 20%;
    }
  }
}

.ntNrContainer {
  display: flex;
  gap: $sds-spaces-xs;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.ntNrSection {
  display: flex;
  flex-direction: column;
  margin-bottom: $sds-spaces-xxxs;
}

.ntNrHeader {
  font: $sds-font-header-xxxs-600-font;
  margin: 0 0 $sds-spaces-xxs 0;
}

.ntNrValue {
  font: $sds-font-body-xs-400-font;
  margin: 0px;
}
