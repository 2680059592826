@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.container {
  display: flex;
  width: 500px;
  align-items: center;
  margin: 0 auto;

  .content {
    flex: 1 0 0;
    margin-right: 0;

    .title {
      @include font-header-l;

      color: $sds-color-gray-black;
    }

    .message {
      @include font-body-s;

      color: $sds-color-gray-black;
      margin-top: $sds-spaces-xxs;
    }

    .suggestion {
      @include font-header-xs;

      color: $sds-color-gray-500;
      margin-top: $sds-spaces-m;
    }

    .link {
      @include font-header-xs;
      // Override color: inherit in _header.scss.
      color: $sds-color-primary-400 !important;
      display: block;
      margin-top: $sds-spaces-m;

      .rightArrow {
        font-size: 0.75em;
        margin-left: $sds-spaces-xs;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  .icon {
    flex: 0 0 auto;
    margin-right: $sds-spaces-m;

    svg {
      height: $img-l;
      width: $img-l;
    }
  }
}
