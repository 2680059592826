@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.highlight {
  @include font-header-xxs;
}

.messageLine {
  @include font-body-xxs;
  padding: $sds-spaces-xxs 0;
  margin-right: $sds-spaces-xl;
}
