// Place all the styles related to the users controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/
@import "~styles/themes/typography";
@import "themes/colors";
@import "themes/elements";
@import "~@czi-sds/components/dist/variables";

$break-large: 1460px;

.user-form {
  width: 35%;
  margin: 0 auto;
  box-shadow: $sds-drop-shadows-shadow-m;
  margin-top: 150px;
  border-radius: $sds-corners-corner-m;
  .title {
    padding: 25px 35px;
    p.signup {
      @include font-header-xxl;
      font-weight: $font-weight-bold;
      color: $sds-color-gray-600;
      margin-top: 9px;
      text-align: left;
    }
  }
  .content-wrapper {
    padding: 40px 50px;
  }
  .login-wrapper {
    background-color: $sds-color-primary-400;
    padding: 15px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: $sds-color-gray-white;
    font-size: 18px;
    cursor: pointer;
  }

  .error-info {
    @include font-body-xxs;
    width: 80%;
    background: $sds-color-error-200;
    border-radius: $sds-corners-corner-m;
    color: $sds-color-error-400;
    margin: 0 auto;
    padding: $sds-spaces-m 5px;
    border: $sds-borders-error-400;
    transition: 0.2s width ease-out;
    text-align: center;
  }
  .success-info {
    @include font-body-xxs;
    width: 80%;
    background: $sds-color-success-200;
    border-radius: $sds-corners-corner-m;
    color: $sds-color-success-400;
    margin: 0 auto;
    padding: $sds-spaces-m 5px;
    border: $sds-borders-success-400;
    transition: 0.2s width ease-out;
    text-align: center;
  }
  .checkbox {
    justify-content: flex-start;
  }
  .input-field {
    i {
      float: right;
    }
    input:focus {
      border-bottom-color: $sds-color-primary-400 !important;
    }
    input:focus + label {
      color: $sds-color-primary-400 !important;
    }
  }
  p .error {
    margin: 0;
  }

  .section {
    margin-bottom: $sds-spaces-m;
    padding: 0;
    .header {
      @include font-header-s;
      margin: $sds-spaces-xxs 0;
    }
  }
}

// Forgot password error style

.forgot-password {
  .form-title {
    font-size: 17px;
    font-weight: 700;
    color: $sds-color-gray-600;
    margin-top: 9px;
    text-align: left;
  }
}
