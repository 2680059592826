@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.sampleUploadFlow {
  .inner {
    padding: 0 60px;
    background-color: $sds-color-gray-white;
  }

  &.narrow .inner {
    padding: 60px 240px;
  }
}

.uploadFlowStep {
  // In order to make the flex-content take up the remaining height on the page,
  // we need to place it in a parent container that expands to fill up the page height.
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;
  flex-direction: column;

  .flexContent {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }

  .controls {
    .helpText {
      @include font-body-xxs;
      margin-bottom: $sds-spaces-m;
      color: $sds-color-gray-500;
    }

    .continueButton {
      margin-right: $sds-spaces-m;
    }
  }

  &.visible {
    display: flex;
  }
}

.label {
  @include font-header-s;
  margin-bottom: 5px;

  .infoLink {
    @include font-caps-xxxs;
    margin-left: $sds-spaces-m;
    color: $sds-color-primary-400;
    cursor: pointer;
  }
}

.headerWrapper {
  border-bottom: 4px solid $sds-color-gray-100;
}

.sampleUploadFlowHeader {
  padding: 20px 60px;
  display: flex;

  .fill {
    flex: 1 1 0;
  }

  .title {
    @include font-header-xxl;
    margin-bottom: 5px;
  }

  .subtitle {
    margin-top: 5px;
    color: $sds-color-gray-500;
  }

  .link {
    // Overwrite <a> inherit rule in header.scss
    color: $sds-color-primary-400;
    cursor: pointer;
    margin-left: 5px;
  }

  .menu {
    display: flex;

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 110px;

      &.active .circle {
        color: $sds-color-gray-white;
        background-color: $sds-color-primary-400;
      }

      &.enabled {
        cursor: pointer;

        .circle {
          color: $sds-color-gray-white;
          background-color: $sds-color-primary-400;
          opacity: 0.3;
        }
      }
    }

    .circle {
      @include font-header-xs;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $sds-spaces-xs;
      // Disable semantic-ui transition.
      transition: none;
    }

    .text {
      @include font-header-xxs;
      color: $sds-color-gray-black;
    }
  }
}

.uploadSampleStep {
  .projectSelect {
    margin-bottom: 30px;

    .header {
      @include font-header-xl;
      margin-bottom: $sds-spaces-default;
    }

    .createProjectButton {
      @include font-caps-xxxs;
      color: $sds-color-primary-400;
      cursor: pointer;
      margin-top: $sds-spaces-xxs;
    }

    .projectCreationContainer {
      background-color: $sds-color-gray-100;
      padding: 20px;
      margin-top: 20px;
    }
  }

  .fileUpload {
    position: relative;
    margin-bottom: $sds-spaces-m;

    .title {
      @include font-header-xl;
    }

    .tabs {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  .limitInfoCalloutContainer {
    margin-top: $sds-spaces-xl;

    .callout {
      width: auto;

      .calloutTitle {
        font: $sds-font-header-xs-600-font;
      }
    }
  }
}

.localFilePicker {
  height: 120px;

  &.short {
    height: 90px;
  }
}

.remoteFileUpload {
  .info {
    background-color: $sds-color-gray-100;
    padding: 20px;
    margin-bottom: $sds-spaces-m;

    .title {
      @include font-header-xs;
    }

    ul {
      margin: 5px 0 $sds-spaces-m 20px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        @include font-body-xs;
        list-style-type: disc;
      }
    }
  }

  .controls {
    display: flex;

    .connectButton {
      margin-left: $sds-spaces-m;
    }

    .input {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .notification {
    margin-top: $sds-spaces-m;
  }
}

.localFileUpload {
  .info {
    background-color: $sds-color-gray-100;
    padding: 20px;
    margin-bottom: $sds-spaces-m;

    .title {
      @include font-header-xs;
    }

    ul {
      margin: 5px 0 $sds-spaces-m 20px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        @include font-body-xs;
        list-style-type: disc;
      }
    }
  }
}

.uploadMetadataStep {
  .title {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-bottom: $sds-spaces-s;
  }

  .uploadInstructions {
    margin-top: $sds-spaces-m;
  }

  .hide {
    display: none;
  }
}
