@import "~styles/themes/elements";

.narrowContainer {
  // Per design, narrow container has a max-width of 1600px with a minimum margin of 14px.
  // Since there is no min-margin property we set max-width to 2xmin_margin and
  // ensure the min margin by removing the margin width from the actual width of
  // the component (i.e. 100% - 2xmin_margin)
  max-width: $narrow-container-width;
  margin: 0 auto;
  width: calc(100% - #{2 * $narrow-container-margin});

  &.small {
    max-width: 800px;
  }
}
