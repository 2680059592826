@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

@import "./taxon_filter_variables.scss";

.dropdown {
  width: $TAXON_THRESHOLD_FILTER_WIDTH;
  margin-bottom: 0px !important;
}

.dropdownPopper {
  min-width: $TAXON_THRESHOLD_FILTER_WIDTH;
  width: fit-content;
  margin-top: $sds-spaces-xs;
}
