@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.content {
  padding: 30px 20px;
  position: relative;
}

.title,
.loadingMsg {
  @include font-header-xl;

  margin: 0 $sds-spaces-m;
  margin-right: 60px;
  word-break: break-all;
}

.subtitle {
  @include font-body-xs;

  color: $sds-color-gray-500;
  margin: 0 $sds-spaces-m;
}

.taxonContents {
  padding: 0 $sds-spaces-m;
}
