@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

$FILTER_WIDTH: 200px;

.dropdown {
  width: $FILTER_WIDTH;
  margin-bottom: 0px !important;
  background-color: white;

  &:hover {
    background-color: white;
  }

  & button {
    max-width: 200px;
  }
}

.dropdownPopper {
  min-width: $FILTER_WIDTH;
  width: fit-content;
  margin-top: $sds-spaces-xs;
}

/* // TODO (mlila): remove this once SDS refactors dropdown */
.userInput {
  :global(.styled-label) {
    color: black;
  }
}
