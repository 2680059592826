@import "~@czi-sds/components/dist/variables";
@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "../PrimaryHeaderControls/primary_header_controls";

.seperator {
  @include font-body-xxxs;
  color: $sds-color-gray-500;
  padding: $sds-spaces-xxs $sds-spaces-xs;
}
