@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.contentContainer {
  font-family: $sds-font-font-family;

  h2 {
    @include font-header-marketing-l;

    @media (max-width: 768px) {
      @include font-header-xxl;
    }

    @media (max-width: 576px) {
      @include font-header-xl;
    }
  }

  .bottomEmailForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    margin-top: 64px;
    text-align: center;
    margin-left: $sds-spaces-xl;
    margin-right: $sds-spaces-xl;

    @media (min-width: 796px) {
      margin-top: 120px;
      margin-bottom: 96px;
      margin-left: auto;
      margin-right: auto;
    }

    h2 {
      font-weight: 600;
      margin-bottom: 48px;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: 0.3px;

      @media (min-width: 769px) {
        font-size: 34px;
        line-height: 46px;
      }
    }

    form input {
      border: 1px solid #d3d3d3 !important;
      margin-bottom: $sds-spaces-m;

      @media (min-width: 769px) {
        margin-bottom: 0;
        border-right: none !important;
      }
    }

    .finePrint {
      margin-top: 16px;
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.3px;

      @media (min-width: 769px) {
        margin-top: $sds-spaces-m;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
      }

      a {
        color: $info-medium !important;

        &:hover {
          text-decoration: none;
          color: $info-dark !important;
        }
      }
    }
  }
}

.summary {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    align-items: center;
  }

  a {
    margin-top: $sds-spaces-xl;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.summaryText {
  width: 33%;
  margin-left: auto;
  margin-top: 65px;

  h2 {
    margin: 0;
    margin-bottom: $sds-spaces-l;
  }

  p {
    @include font-body-m;
    margin: 0px;
    color: $sds-color-gray-600;

    @media (max-width: 768px) {
      @include font-body-s;
      padding-right: 15px;
    }
  }

  @media (max-width: 768px) {
    width: fit-content;
    margin-top: 20px;
    padding-left: $sds-spaces-xl;
    padding-right: $sds-spaces-xl;
  }
}

.summaryImage {
  width: 55%;
  margin-top: -8%;

  @media (max-width: 768px) {
    align-self: flex-end;
    width: 100%;
    margin-top: -15%;
  }

  img {
    width: 100%;
  }
}

.healthQuestions {
  text-align: center;
  margin: 4rem auto 5rem auto;

  @media (max-width: 768px) {
    padding-left: $sds-spaces-xl;
    padding-right: $sds-spaces-xl;
  }

  h2 {
    margin-bottom: $sds-spaces-l;
  }

  figure {
    display: inline-block;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }

    img {
      width: 100%;
      max-width: 979px;
    }
  }

  .healthQuestionsFigure {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .healthQuestionsMobileFigure {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.howItWorks {
  margin-top: 120px;
  padding-top: 96px;
  padding-bottom: 96px;
  margin-bottom: 160px;
  text-align: center;
  background: $gray-off-white;

  @media (max-width: 768px) {
    padding-top: 64px;
    margin-top: 64px;
    padding-bottom: 64px;
    margin-bottom: 0;
    padding-left: $sds-spaces-xl;
    padding-right: $sds-spaces-xl;
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.3px;
    margin-bottom: 24px;

    @media (min-width: 769px) {
      font-size: 34px;
      line-height: 46px;
      margin-bottom: 32px;
    }
  }

  p {
    max-width: 765px;
    margin: 0 auto;
    @include font-body-m;
    color: $sds-color-gray-600;

    @media (max-width: 768px) {
      @include font-body-s;
    }
  }
}

.howItWorksProcess {
  display: flex;
  justify-content: space-evenly;
  margin-top: 48px;
  max-width: 1152px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-right: 40px;
  padding-left: 40px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 40px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
  }

  img {
    height: 100px;
    width: auto;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    padding-top: 24px;
    margin-top: 0;

    @media (min-width: 769px) {
      font-size: 16px;
      line-height: 22px;
      padding-top: 32px;
    }
  }

  p {
    @include font-body-xs;
    max-width: 225px;
    color: $sds-color-gray-600;
  }
}

.howItWorksSeparator {
  width: 121px;
  height: 0px;
  border-bottom: 2px dotted #ddd;
  margin-top: 45px;

  @media (max-width: 768px) {
    display: none;
  }
}

.tabbedGallery {
  display: flex;
  justify-content: center;
}

.questions {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $gray-off-white;
  padding-top: 77px;
  padding-bottom: 64px;

  @media (max-width: 768px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  a {
    margin: 40px auto 0;

    @media (min-width: 769px) {
      margin: 32px auto 0;
    }
  }
}
