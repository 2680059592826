@import "~styles/themes/elements";
@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.auth0Error {
  height: calc(100vh - #{$header-total-height});

  .imgMicrobe {
    width: $img-m;
    height: $img-m;
  }

  .helpLink {
    // Override header.scss.
    color: $sds-color-primary-400 !important;
  }
}
