@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.uploadConfirmationModal {
  .title {
    @include font-header-xl;
    color: $sds-color-gray-black;
  }

  .body {
    @include font-body-s;
    margin-top: $sds-spaces-s;
    margin-bottom: $sds-spaces-xl;
    color: $sds-color-gray-600;
  }

  .actions {
    margin-top: $sds-spaces-m;
    display: flex;

    .item {
      cursor: pointer;

      &:not(:first-child) {
        margin-left: $sds-spaces-xs;
      }

      &:not(:last-child) {
        margin-right: $sds-spaces-xs;
      }
    }
  }
}
