@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

:global(.ui):global(.label) {
  &.filterTag {
    @include font-body-xxxs;
    background: $sds-color-primary-400 !important;
    border-color: $sds-color-primary-400 !important;
    color: $sds-color-gray-white !important;

    .closeIcon {
      cursor: pointer;
      flex-shrink: 0;
      fill: $sds-color-gray-white;
      height: $sds-icon-sizes-xs-height;
      width: $sds-icon-sizes-xs-width;
      margin-left: $sds-spaces-xxs;
    }
  }

  .labelText {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
