@import "../../../../sample_upload_flow_shared";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.uploadProgressModalSampleList {
  margin: $sds-spaces-xxl $sds-spaces-xxl 0;
  max-height: 200px;
  overflow: auto;

  .sample {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  .sampleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sampleName {
      @include font-header-s;
      flex: 0 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .sampleStatus {
      @include font-body-xxs;
      color: $sds-color-gray-600;
      display: flex;
      align-items: center;
      margin-left: $sds-spaces-m;
      white-space: nowrap;

      .alertIcon {
        fill: $sds-color-error-400;
        width: 14px;
        height: 14px;
        margin-right: 5px;
        margin-bottom: $sds-spaces-xxxs;
      }

      .checkmarkIcon {
        width: 16px;
        height: 16px;
        margin-bottom: -$sds-spaces-xxs;
        margin-right: 5px;
        fill: $sds-color-success-400;
      }

      .sampleRetry {
        @include sampleUploadRetry;
      }
    }

    .verticalDivider {
      @include font-body-l;
      color: $sds-color-gray-300;
      padding: 0 $sds-spaces-xxs $sds-spaces-xxs;
    }
  }
}
