@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.vrCompatibilityText {
  font-style: italic;
  margin-top: 30px !important;
}

.vrContainer {
  width: 100%;
  max-width: 1000px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  margin: 40px auto 0 auto;
  filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771))
    drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221))
    drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786))
    drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291));

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
}

.oculusContainer,
.cardboardContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 992px) {
    width: 100%;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 60px;
    padding: 0 2em;

    @media (max-width: 992px) {
      margin-top: 40px;
    }
  }

  p,
  ol {
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    color: #545454;
    max-width: 350px;
    font-family: $font-family-sans-serif, sans-serif;
    font-weight: 400;

    @media (max-width: 992px) {
      max-width: none;
      padding: 0 20px;
    }
  }

  ol {
    margin: 0;
    margin-bottom: 1em;

    li {
      margin-bottom: 8px;

      img {
        width: 2em;
        display: inline;
        margin: 0;
      }
    }

    padding-inline-start: 40px;
  }

  img {
    margin-bottom: 40px;
  }

  a.btnLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 34px;
    color: #3867fa !important;
    border-radius: 24px;
    border: 1px solid #3867fa;
    text-decoration: none;
    font-family: $font-family-sans-serif, sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    transition: 0.1s ease;
    align-self: flex-start;
    margin-left: 75px;
    margin-top: 30px;
    margin-bottom: 60px;

    &:hover {
      background: #3867fa;
      color: #ffffff !important;
    }

    @media (max-width: 992px) {
      margin-left: 0;
      align-self: center;
    }
  }

  a.vrInlineLink {
    color: #3867fa !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.oculusContainer {
  @media (max-width: 992px) {
    border-right: none;
    border-top: 1px solid #eaeaea;
  }
}

.cardboardContainer {
  border-right: 1px solid #eaeaea;

  @media (max-width: 992px) {
    padding-bottom: 30px;
    border-right: none;
  }
}
