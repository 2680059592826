@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

// Exporting strings to style nodes and edges in vis.js
:export {
  defaultNodeText: $sds-color-gray-black;

  notStartedBg: $sds-color-gray-white;
  notStartedText: scale-color($sds-color-gray-black, $alpha: -50%);
  notStartedShadow: scale-color($box-shadow-light, $alpha: -50%);

  inProgressBg: $sds-color-primary-200;
  inProgressHoverBg: hover-state($sds-color-primary-200);

  finishedBg: $sds-color-success-100;
  finishedHoverBg: hover-state($sds-color-success-100);

  pipelineErroredBg: $sds-color-error-100;
  pipelineEroredHoverBg: hover-state($sds-color-error-100);

  userErroredBg: $sds-color-warning-100;
  userErroredHoverBg: hover-state($sds-color-warning-100);

  stageBg: $sds-color-gray-100;
  defaultEdgeColor: $sds-color-gray-400;
  outputEdgeColor: $sds-color-primary-400;
  inputEdgeColor: $sds-color-gray-black;

  nodeShadowColor: $box-shadow-light;
}

.pipelineVizPage {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 50px);
}

.pipelineVizContainer {
  flex-grow: 1;
  position: relative;
}

.panZoomContainer {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  cursor: all-scroll !important;
  outline: none;
  & > div {
    margin: 0 auto;
    align-self: stretch;
    display: flex !important; /* Override existing library styling */
    align-items: center;
    padding: 150px;
  }
}

.pipelineViz {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.stageAndArrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stage {
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.stageButton {
  @include font-caps-xxs;
  background-color: $sds-color-gray-white;
  border-radius: $sds-corners-corner-m;
  padding: $sds-spaces-xs $sds-spaces-s;
  cursor: pointer;
  box-shadow: $sds-drop-shadows-shadow-s;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.finished {
    background-color: $sds-color-success-200;
  }

  &.notStarted {
    background-color: $sds-color-gray-white;
    color: $sds-color-gray-500;
  }

  &.inProgress {
    background-color: $sds-color-primary-200;
  }

  &.pipelineErrored {
    background-color: $sds-color-error-200;
  }

  &.userErrored {
    background-color: $sds-color-warning-100;
  }
}

.stageNameAndIcon {
  display: flex;
  align-items: center;
  height: 20px;

  .inProgressIcon,
  .finishedIcon {
    height: $icon-s;
    width: $icon-s;
  }

  .inProgressIcon {
    color: $sds-color-primary-400;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    cursor: default;
  }

  .pipelineErroredIcon {
    fill: $sds-color-error-400 !important;
  }

  .userErroredIcon {
    fill: $sds-color-warning-400 !important;
  }

  .finishedIcon {
    fill: $sds-color-success-400;
  }

  .stageName {
    margin: 2.5px 0;
  }

  // Only give the stage name a margin-left if there is
  // an icon preceding it.
  & > .stageName:nth-child(2) {
    margin-left: $sds-spaces-xxs;
  }
}

.stageArrow {
  width: 30px;
  height: 10px;
  display: flex;
  align-items: center;
  overflow: visible;
  position: relative;
  flex-shrink: 0;
  pointer-events: none;

  .stageArrowBody {
    position: relative;
    width: calc(100% - 8px);
    padding: 0.5px 0;
    background-color: $sds-color-gray-400;

    &.fromColoring {
      background-color: $sds-color-gray-black;
      padding: 1px 0;
    }

    &.toColoring {
      background-color: $sds-color-primary-400;
      padding: 1px 0;
    }
  }

  .stageArrowHead {
    position: absolute;
    right: 0;
    height: 75%;
    fill: $sds-color-gray-400;

    &.fromColoring {
      fill: $sds-color-gray-black;
    }

    &.toColoring {
      fill: $sds-color-primary-500;
    }
  }
}

.graphLabel {
  @include font-caps-xxs;
  padding: $sds-spaces-l $sds-spaces-l 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  top: 0;

  .closeIcon {
    box-sizing: content-box;
    fill: $sds-color-gray-300;

    &:hover {
      fill: $sds-color-gray-black;
    }
  }
}

.hidden {
  display: none;
}

.graph {
  padding: 60px 0;
}

.openedStage {
  background-color: $sds-color-gray-100;
  border-radius: $sds-corners-corner-m;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  position: relative;
  height: 100%;
}

.plusMinusControl {
  position: fixed;
  bottom: 50px;
  right: 15px;
  box-shadow: $sds-drop-shadows-shadow-m;
  svg:hover {
    background-color: darken(white, 5%);
  }
}

.hovered {
  cursor: pointer;
}

.headerSection {
  padding-bottom: 48px;
}

.pipelineInfo {
  @include font-body-xxxs;
  color: $sds-color-gray-400;
  margin-top: 24px;
  margin-bottom: 18px;
}

.headerDivider {
  height: 3px;
  background-color: $sds-color-gray-200;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}
