@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.form {
  width: 350px;
  margin: 0 auto;
  box-shadow: $sds-drop-shadows-shadow-m;
  margin-top: 150px;
  border-radius: $sds-corners-corner-l;
  padding-top: $sds-spaces-default;

  .mainContent {
    margin-bottom: $sds-spaces-xl;
  }

  .title {
    @include font-header-l;
    padding: $sds-spaces-xl $sds-spaces-xxl 0 $sds-spaces-xxl;
  }

  .content {
    @include font-body-s;
    padding: $sds-spaces-l $sds-spaces-xxl;

    .emailLabel {
      margin-left: $sds-spaces-s;
      // Override materialize
      color: inherit !important;
      -webkit-transform: none !important;
      transform: none !important;
    }

    .inputField {
      @include font-body-xs;
      width: 100%;
    }
  }

  .submit {
    @include font-header-m;

    background-color: $sds-color-primary-400;
    border-radius: 0 0 $sds-spaces-default $sds-spaces-default;
    border: none;
    color: $sds-color-gray-white;
    cursor: pointer;
    padding: $sds-spaces-l;
    width: 100%;
  }

  .text {
    @include font-body-s;
    padding: $sds-spaces-xl;
  }
}
