@import "../../themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.phylo-tree-creation-wizard {
  min-height: 440px;
}

.wizard {
  &__page-1,
  &__page-2,
  &__page-3,
  &__page-4 {
    &__subtitle {
      color: $sds-color-gray-500;
      font-weight: 700;
      height: 16px;
      line-height: 16px;
      margin-bottom: 20px;
      text-transform: capitalize;
      vertical-align: middle;
    }

    &__table {
      max-height: 300px;
      margin-bottom: 20px;

      tbody {
        max-height: 260px;
      }

      // custom style for data tables
      .data-table {
        // applies to header and data
        .column-name {
          width: 250px;
          text-align: left;
        }

        &__header {
          text-align: center;
        }

        &__data {
          text-align: center;

          &.column-name {
            font-weight: 700;
          }

          &.column-view {
            color: $sds-color-primary-400;
            font-weight: 700;
          }
        }
      }
    }

    &__notifications {
      max-height: 40px;
    }
  }

  &__page-1 {
    &__action {
      color: $sds-color-primary-400;
      font-weight: 700;
    }
  }

  &__page-3 {
    &__form {
      margin-bottom: $sds-spaces-m;
      height: 50px;

      div {
        float: left;
        height: 50px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      &__label-name,
      &__label-branch {
        font-weight: 700;
        float: left;
        margin-right: 20px;
      }

      input {
        float: left;
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }

    &__table {
      height: 300px;
    }
  }

  &__page-2,
  &__page-4 {
    padding-top: $sds-spaces-m;

    &__searchbar {
      height: 50px;
      margin-bottom: $sds-spaces-xxl;

      &__container {
        float: left;
        font-weight: 700;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;

        &:not(:first-child) {
          margin-left: 20px;
        }
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }

    &__table {
      height: 300px;
    }
  }

  &__page-4 {
    &__subtitle {
      display: none;
    }
  }

  &__page-3,
  &__page-4 {
    &__table {
      max-height: 250px;

      tbody {
        max-height: 210px;
      }
    }

    &__notifications {
      max-height: 40px;
      margin-top: $sds-spaces-xl;
      margin-bottom: $sds-spaces-xxl;
    }
  }

  &__error {
    color: $sds-color-error-400;
  }
}
