@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.viewHeader {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;

  .content {
    margin-right: 20px;
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }

  .controls {
    flex-shrink: 0;
  }

  .sampleDropdown {
    @include font-header-xxl;
  }

  .sampleName {
    @include font-header-xl;
    color: $sds-color-gray-black;
    margin: 0;
    max-width: 800px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .trigger {
    margin-right: $sds-spaces-m;
    user-select: none;
    display: inline-block;

    &:hover {
      color: $sds-color-primary-400;
    }
  }

  .pretitle {
    @include font-header-xxs;
    color: $sds-color-gray-500;
    margin-bottom: $sds-spaces-xxxs;
    margin-left: 1px;
    display: flex;

    .link {
      max-width: 600px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:hover {
        /* Overwrite header.scss */
        color: $sds-color-primary-400 !important;
      }
    }

    .leftArrow {
      fill: $sds-color-gray-500;
    }
  }
}

.sampleNamePopup {
  text-align: left;
  /* override semantic-ui */
  max-width: 500px !important;
  word-break: break-all;
}
