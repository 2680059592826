@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.technologyDescription {
  @include font-body-xxs;
  color: $sds-color-gray-600;

  .externalLink {
    color: $sds-color-primary-400;
    cursor: pointer;
  }
}

.technology {
  padding: $sds-spaces-m;

  &:hover {
    background-color: $sds-color-gray-200;
  }

  &.selected {
    background-color: $sds-color-gray-200;
  }
}
