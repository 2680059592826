@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.workflowSelector {
  margin-bottom: $sds-spaces-xxl;
}

.header {
  @include font-header-xl;
  margin-bottom: $sds-spaces-default;
}

.selectableOption {
  border-radius: $sds-corners-corner-m;
  cursor: pointer;
  display: flex;
  padding: $sds-spaces-l $sds-spaces-l $sds-spaces-xl $sds-spaces-l;
  margin-bottom: $sds-spaces-xs;
  width: 100%;

  &:hover {
    background-color: $sds-color-gray-100;
  }

  &.selected {
    background-color: $sds-color-gray-100;
  }

  &.disabled {
    cursor: default;
    background-color: initial;
    color: $sds-color-gray-300;
  }
}

.technologyOptions {
  margin-top: $sds-spaces-xxxs;
}

.checkbox {
  flex-shrink: 0;
  align-items: flex-start;
  margin-top: $sds-spaces-xxs;

  &.alignTitle {
    padding-top: $sds-spaces-xxs;
    margin-top: 0px;
  }
}

.radioButton {
  flex-shrink: 0;
  align-items: unset;
  padding: 0px $sds-spaces-s 0px 0px;

  &.alignTitle {
    margin-top: $sds-spaces-xxs;
  }
}

.title {
  @include font-header-s;
}

.technologyTitle {
  padding-top: $sds-spaces-l;
}

.description {
  @include font-body-s;
}

.technologyContent {
  display: flex;
  flex-wrap: wrap;
}

.item {
  display: flex;
  flex-direction: column;
  min-width: 160px;
  margin-right: 100px;
  margin-top: $sds-spaces-l;

  @media (max-width: 1200px) {
    flex: 1 1 160px;
  }
}

.subheader {
  @include font-header-xs;
  margin-bottom: $sds-spaces-xs;
}

.text {
  margin-top: $sds-spaces-xs; // align plaintext with text in toggle and dropdown menus
}

.dropdown {
  @include font-body-xs;
  min-width: 215px;
}

.dropdownMenu {
  min-width: max-content !important;
}

.clearLabs {
  min-width: 130px;
}

.disabledSvgIcon {
  path {
    fill: $sds-color-gray-200;
  }
}

.optionText {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
