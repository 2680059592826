@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.displaySwitcher {
  .switcherMenu {
    margin: 0;
    min-height: 25px;
    border-radius: $sds-corners-corner-m;
    box-shadow: none;

    .menuItem {
      padding: 5px 15px;

      .icon {
        fill: $sds-color-gray-600;
        height: $icon-s;

        &.active {
          fill: $sds-color-primary-400;
        }
      }
    }
  }
}
