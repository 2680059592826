@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.privacyNoticeForUserResearch {
  margin-top: 90px;
  margin-bottom: 90px;

  h1 {
    @include font-header-xxl;
    margin-bottom: 0;
  }

  h2 {
    @include font-header-xl;
    position: relative;
    margin-top: $sds-spaces-l;
  }

  h3 {
    @include font-header-m;
    margin-top: $sds-spaces-l;
    position: relative;

    &.first {
      margin-top: 0;
    }
  }

  h4.subtitle {
    @include font-body-xxs;
    margin-top: $sds-spaces-xxs;
    color: $sds-color-gray-500;
  }

  .title {
    margin-bottom: $sds-spaces-l;
  }

  p {
    @include font-body-s;

    &.last {
      padding-bottom: $sds-spaces-xxl;
    }

    &.list {
      margin-bottom: $sds-spaces-xxs;
    }

    &.providers {
      margin-bottom: $sds-spaces-l;
    }
  }

  a {
    // Override _header.scss
    color: $sds-color-primary-400 !important;
  }

  ul {
    margin-left: 40px;
    margin-top: 0;

    li {
      @include font-body-s;
      list-style-type: disc;
      margin-bottom: $sds-spaces-m;
    }
  }

  .nestedList {
    margin: 0 0 20px 40px;

    ol {
      margin-top: $sds-spaces-xxs;
    }
  }

  .italics {
    font-style: italic;
    margin-bottom: 18px;
  }
}
