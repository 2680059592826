@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.hero {
  font-family: $sds-font-font-family;
  background-color: $sds-color-gray-black;
  background: url("../../../../../images/landing_page/idseq-hero.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 85%;
  height: 434px;
  padding-top: 65px;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  @media (max-width: 768px) {
    padding: 40px $sds-spaces-xl 0;
    background: url("../../../../../images/landing_page/idseq-hero-mobile.svg");
    background-color: $sds-color-gray-black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 100%;
    height: 300px;
  }

  h1 {
    color: $sds-color-gray-white;
    font-weight: $font-weight-semibold;
    margin: 0;
    @include font-header-marketing-xl;
    transition: all 0.4s;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    @media (max-width: 905px) {
      @include font-header-marketing-l;
    }

    @media (max-width: 768px) {
      @include font-header-xxl;
      line-height: 44px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    @media (max-width: 397px) {
      @include font-header-xl;
    }

    @media (max-width: 341px) {
      font-size: 20px;
    }
  }

  p {
    color: $sds-color-gray-white;
    margin-bottom: 40px;
    margin-top: $sds-spaces-xs;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    max-width: 527px;
    @include font-body-m;

    @media (max-width: 768px) {
      @include font-body-s;
      line-height: 23px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    @media (max-width: 526px) {
      @include font-header-xs;
    }
  }

  &ButtonLink {
    color: $sds-color-gray-white !important;
    background: #000000;
    text-decoration: none;
    font-family: $sds-font-font-family;
    padding: 7px $sds-spaces-l;
    margin: 0 5px;
    @include font-body-xs;
    font-weight: $font-weight-semibold;
    display: inline-block;
    text-align: center;
    border: 1px solid $sds-color-gray-white;
    border-radius: 999px;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    &:hover {
      background: $sds-color-gray-white;
      color: $sds-color-gray-black !important;
    }
  }
}
