@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.text {
  @include font-body-xxs;
  fill: $sds-color-gray-black;

  &:hover {
    fill: $sds-color-primary-400;
    cursor: pointer;
  }
}
