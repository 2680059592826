.toastContainer {
  &:global(.Toastify__toast-container) {
    width: 420px;

    :global(.Toastify__toast) {
      box-shadow: none;
      min-height: 0;
      padding: 5px;
      margin-bottom: 5px;
      background: transparent;

      &:-body {
        margin: 0;
      }
    }
  }
}
