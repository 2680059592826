@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.modal {
  max-height: calc(85vh - 80px);
  display: flex;
  flex-direction: column;

  .header {
    .title {
      @include font-header-xl;
      color: black;
      // Reduce the amount of margin at the top of the modal.
      margin-top: -$sds-spaces-s;
    }

    .tagline {
      @include font-body-xs;
      color: $sds-color-gray-500;
    }
  }

  .options {
    overflow: auto;
  }

  .footer {
    margin-top: $sds-spaces-l;
  }
}
