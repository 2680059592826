@import "~@czi-sds/components/dist/variables";

.rightAlignedHeader {
  div {
    justify-content: flex-end;
  }
}

.rightAlignedCell {
  text-align: right;
  padding-right: 30px; // This is awkwardly in between sds sizes
  height: 50px;
  span {
    font: $sds-font-body-s-600-font;
    word-break: break-word;
  }
}

.leftAlignedCell {
  height: 50px;
  span {
    font: $sds-font-body-s-600-font;
    word-break: break-word;
  }
}

.headerWithTagContainer {
  display: flex;
  flex-direction: row;
}

.tag {
  margin-right: 0px;
  margin-left: $sds-spaces-xs;
  margin-bottom: 0px;
}

.groupedHeader {
  align-items: center;
  background-color: $sds-color-gray-100;
  border-left: $sds-borders-gray-300;
  color: $sds-font-color;
  font: $sds-font-header-xxs-600-font;
  height: 30px;
  margin: $sds-spaces-s;
}

.groupedHeaderButton {
  width: 100%;
  border-radius: 0;
  &:hover {
    background-color: $sds-color-primary-200;
  }
  svg {
    position: absolute;
    left: $sds-spaces-s;
  }
  span {
    margin-left: $sds-spaces-s;
  }
}

.groupedHeaderPlaceholder {
  background-color: $sds-color-gray-100;
  height: 30px;
  margin: $sds-spaces-s;
}

.rowCountHeader {
  height: 30px;
}

.rowCount {
  color: $sds-color-gray-500;
  display: flex;
  font: $sds-font-body-xxs-400-font;
}
