@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.semibold {
  font-weight: $font-weight-semibold;
}

.accordion {
  margin-top: $sds-spaces-m;
}

.messageLine {
  @include font-body-xxs;
  padding: $sds-spaces-xxs 0;
  margin-right: $sds-spaces-xl;
}
