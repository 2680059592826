@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.footer {
  .downloadDisclaimer {
    @include font-body-xxs;
    color: $sds-color-gray-500;
    margin-top: $sds-spaces-xs;
  }
}

.highlight {
  @include font-header-xxs;
}

.listContainer {
  background-color: $sds-color-warning-100;
  margin-bottom: $sds-spaces-l;
}

.notificationContainer {
  margin: $sds-spaces-l 0 $sds-spaces-l;

  .errorMessage {
    @include font-header-xxs;
  }
}

.messageLine {
  @include font-body-xxs;
  padding: $sds-spaces-xxs 0;
  margin-right: $sds-spaces-xl;
}

.notifications {
  margin-bottom: $sds-spaces-xl;
}

.loading {
  @include font-body-s;
  padding: 0 0 0 $sds-spaces-default;
  color: $sds-color-gray-500;
  display: inline-block;

  .loadingIcon {
    fill: $sds-color-gray-500;
    width: $icon-s;
    height: $icon-s;
    vertical-align: -0.1em;
  }
}
