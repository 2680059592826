@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.modal {
  overflow-y: visible; // allow dropdown menu to extend past the modal

  .title {
    @include font-header-xxl;
    margin-bottom: $sds-spaces-s;
  }

  .description {
    @include font-body-s;
    color: $sds-color-gray-600;
  }

  .label {
    @include font-header-xs;
    margin-top: $sds-spaces-xl;
    .taxonName {
      @include font-body-xs;
    }

    .infoIcon {
      margin-left: $sds-spaces-xxs;
      margin-top: $sds-spaces-xxxs;
      vertical-align: -$sds-spaces-xxxs;
    }
  }

  .dropdown {
    margin-top: $sds-spaces-s;
  }

  .button {
    margin-top: $sds-spaces-xl;
    margin-bottom: $sds-spaces-xxl;
    position: absolute;
    bottom: 0;
  }
}
