@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.semibold {
  font-weight: $font-weight-semibold;
}

.list {
  margin: $sds-spaces-xs 0 $sds-spaces-l 0;
}
