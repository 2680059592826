@import "../../themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

// Override semaantic ui error styling
:global(.ui.input.error > input) {
  background-color: transparent;
  color: $sds-color-gray-black;
}

.idseq-ui.input {
  input {
    border-radius: $sds-corners-corner-m;
    border: $sds-borders-gray-300;
    box-sizing: border-box;

    &:focus {
      border: $sds-borders-primary-400;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  input[type="text"],
  input[type="number"] {
    @include font-body-xs;
    height: 34px;
    width: 100%;
  }

  input[type="header"] {
    @include font-header-xl;
    padding: 7px $sds-spaces-l;
    text-overflow: ellipsis;
    background-color: transparent;
    color: $sds-color-gray-black;
    height: 44px;
    width: 600px;
  }

  &.error {
    input[type="header"],
    input[type="text"] {
      border: $sds-borders-error-400 !important;
    }
  }

  &.warning {
    input[type="header"],
    input[type="number"],
    input[type="text"] {
      border: $sds-borders-warning-400 !important;
    }
  }

  // Remove arrows/spinners from numeric HTML form inputs
  &.noSpinner {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
