@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.header {
  align-items: center;
  background-color: $sds-color-gray-black;
  display: flex;
  flex-direction: row;
  // Header height might influence layout on other pages that use calc to compute available height.
  // See discovery view layout for instance
  height: $header-height;
  padding: 0 15px;
  position: relative;
  z-index: 900;

  .logo {
    flex: 0 0 auto;

    .icon {
      height: 25px;
      margin: 5px;
    }
  }

  .fill {
    flex: 1 0 auto;
  }

  .userDropdown {
    flex: 0 0 auto;
    color: $sds-color-gray-white;
    margin-right: $sds-spaces-s;

    .userName {
      @include font-body-xs;

      align-items: center;
      display: flex;
      font-weight: $font-weight-regular;
      margin-right: 5px;
      height: 36px;
      vertical-align: middle;
    }

    .option {
      display: block;
      width: 100%;
      color: $sds-color-gray-black !important;
    }
  }

  .termsDropdown {
    flex: 0 0 auto;
    color: $sds-color-gray-white;

    .terms {
      @include font-body-xs;

      align-items: center;
      display: flex;
      font-weight: $font-weight-semibold;
      color: $sds-color-gray-500;
      margin-right: 5px;
      height: 36px;
      vertical-align: middle;
    }

    .option {
      display: block;
      width: 100%;
      color: $sds-color-gray-black !important;
    }
  }

  .termsDropdown i:before {
    color: $sds-color-gray-500 !important;
  }

  .mainMenu {
    @include font-body-xs;
    margin-right: 100px;
    display: flex;
    color: $sds-color-gray-500;

    .item {
      font-weight: $font-weight-semibold;

      &.selected {
        color: $sds-color-gray-white !important;
      }
    }

    .item:not(:last-child) {
      margin-right: 25px;
    }
  }

  .loggedOutMainMenu {
    @include font-body-xs;
    display: flex;
    color: $sds-color-gray-500;

    .item {
      font-weight: $font-weight-semibold;
      margin-right: 25px;

      &.selected {
        color: $sds-color-gray-white !important;
      }
    }
  }

  .logout {
    font: $sds-font-header-xs-600-font;
    display: flex;
    color: $sds-color-gray-white;
    margin-right: $sds-spaces-s;

    &:hover {
      cursor: pointer;
    }
  }
}

.notificationLink {
  text-decoration: underline;
}

.backgroundRefreshFrame {
  display: none;
}
