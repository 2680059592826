@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.projectCreationModal {
  .modalTitle {
    @include font-header-xl;
    color: $black;
  }

  .label {
    @include font-header-s;
    margin-top: $sds-spaces-xl;
    margin-bottom: $sds-spaces-s;

    .infoLink {
      @include font-caps-xxxs;
      margin-left: $sds-spaces-xxs;
      color: $sds-color-primary-400;
      cursor: pointer;
    }
  }

  .optionalLabel {
    @include font-body-xxs;
    color: $sds-color-gray-500;
  }

  .descriptionTextArea {
    @include font-body-xs;
    background-color: $sds-color-gray-white;
    height: 100px;
    padding: $sds-spaces-l;
    resize: vertical;
    text-indent: $sds-spaces-xs;
    width: 100%;
  }

  .charCounter {
    @include font-body-xxs;
    color: $sds-color-gray-500;
    text-align: right;
  }

  .field {
    .info {
      background-color: $sds-color-gray-100;
      padding: $sds-spaces-l;
      margin-bottom: $sds-spaces-l;
      border-radius: $sds-corners-corner-m;

      .title {
        @include font-header-xs;
      }

      ul {
        margin: $sds-spaces-l 0 $sds-spaces-m 20px;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          @include font-body-xs;
          list-style-type: disc;
        }
      }

      p {
        @include font-body-xs;
      }
    }
  }

  .sharingOption {
    display: flex;
    align-items: flex-start;
    padding: $sds-spaces-l 0;
    margin-bottom: $sds-spaces-xxs;
    border-radius: $sds-corners-corner-m;
    cursor: pointer;

    &:hover {
      background-color: $sds-color-gray-100;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .radioButtonAndProjectIcon {
      display: flex;
      align-items: center;

      .radioButton {
        margin: 0px $sds-spaces-xs;
        flex: 1 0 auto;
      }

      .projectIcon {
        flex: 1 0 auto;
        margin-right: $sds-spaces-m;
      }
    }

    .optionText {
      .title {
        @include font-header-s;
      }

      .description {
        @include font-body-s;
      }
    }
  }

  .controls {
    display: flex;
    margin-top: $sds-spaces-xxl;
  }

  .error {
    color: $sds-color-error-400;
    margin-left: $sds-spaces-m;
    margin-bottom: $sds-spaces-m;
  }

  .cancelButton {
    margin-left: $sds-spaces-default;
  }
}
