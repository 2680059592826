@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.descriptionContainer {
  color: $sds-color-gray-500;
  padding-left: $sds-spaces-default;

  .truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .textarea {
    @include font-body-xs;
    height: 200px;
    margin-top: 5px;
    padding: 5px;
    resize: vertical;
    width: 100%;
  }

  .showHide {
    @include font-body-xxs;
    text-transform: uppercase;
    display: inline-block;
    margin: $sds-spaces-xs 0 $sds-spaces-default 0;
    color: $sds-color-primary-400;
    cursor: pointer;
  }

  .charCounter {
    @include font-body-xxs;
    color: $sds-color-gray-500;
  }

  .error {
    @include font-body-xxs;
    color: $sds-color-error-400;
  }
}
