@use "sass:math";

@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.publicationsAndNews {
  padding: 0 $sds-spaces-xl;
  margin: 80px auto;
  max-width: 1280px;

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    margin: 100px auto 0px;
    padding: 0 100px;
  }

  @media (min-width: 1280px) {
    padding: 0 150px;
  }

  @media (max-width: 768px) {
    > * + * {
      margin-top: 3em;
      padding-top: 3em;
      border-top: 1px solid #ddd;
    }
  }

  h2 {
    color: $sds-color-gray-600;
    letter-spacing: 0.01875em;
    text-transform: uppercase;
    margin: $sds-spaces-xs 0 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.3px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .publications,
  .news {
    width: 100%;
  }

  .publications {
    @media (min-width: 769px) {
      position: relative;
      width: percentage(math.div(2, 3));
      padding: 0 6% 0 20px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(128, 128, 128, 0.2);
      }
    }
  }

  .news {
    @media (max-width: 768px) {
      margin-top: 64px;
      padding-top: 80px;
    }

    @media (min-width: 769px) {
      width: percentage(math.div(1, 3));
      padding-bottom: 2em;
      padding: 0 20px 0 6%;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .date {
      display: block;
      @include font-header-xs;
      font-weight: 400;
    }

    .date,
    .source {
      color: $sds-color-gray-600;
    }

    .title {
      color: $sds-color-gray-black;
      margin: $sds-spaces-xs 0 0 0;
      @include font-header-l;
    }

    .source,
    .readMore {
      @include font-body-s;
    }

    .source {
      margin: 15px 0 0 0;
    }

    .readMore {
      color: $sds-color-primary-400;
      margin: 12px 0 0 0;
      font-weight: 600;

      &:hover,
      &:active {
        color: $sds-color-primary-600;
      }
    }

    a {
      display: block;
      text-decoration: none;
    }
  }

  li {
    margin: 32px 0;

    &.marker {
      display: flex;

      h3 {
        margin-top: 0;
      }
    }

    .liMarker {
      width: 19px;
      height: $sds-spaces-xxxs;
      background: $sds-color-gray-black;
      margin-right: 17px;
      flex-shrink: 0;
      margin-top: 0.75em;
    }
  }
}
