@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

li.navLink {
  @include font-body-xs;
  padding-left: $sds-spaces-l;
  max-width: 180px;
  height: 20px;
  margin-left: -$sds-spaces-xxxs;

  &.currentNavItem {
    border-left: 2px solid $sds-color-primary-400;
    color: $sds-color-gray-black;
    font-weight: $font-weight-semibold;
  }

  &.navItem {
    border-left: 2px solid $sds-color-gray-200;
    color: $sds-color-gray-500 !important;
  }

  &:hover {
    border-left: 2px solid $sds-color-gray-400;
    color: $sds-color-gray-black !important;
  }

  &:not(:last-child) {
    margin-bottom: $sds-spaces-m;
  }
}
