@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.error {
  fill: $sds-color-error-400;
}

.warning {
  fill: $sds-color-warning-400;
}

.info {
  fill: $sds-color-primary-400;
}
