@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.announcementBanner {
  @include font-body-xs;
  background: $sds-color-primary-200;
  height: 40px;
  position: relative;
  text-align: center;
  z-index: 900;

  .content {
    display: block;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .icon {
      fill: $sds-color-primary-400;
      height: 20px;
      margin-right: $sds-spaces-s;
      vertical-align: text-bottom;
    }
  }
}

.links {
  @media screen and (max-width: 768px) {
    display: none;
  }

  margin-top: 1.1rem;

  .headerLink {
    color: $sds-color-gray-400 !important;
    margin-right: 2.6rem;
  }
}

.header {
  margin: 0;

  .siteHeader {
    @include font-body-s;
    line-height: unset;
    letter-spacing: unset;
    height: 50px;
    background-color: $sds-color-gray-black;
    position: relative;
    z-index: 1000;
    display: flex;
    padding: 0 0.75rem;

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
  }
}

.brandDetails {
  .logoIcon {
    svg {
      height: 2.5rem;
      margin-top: 0.5rem;
      margin-left: 0.3rem;
    }
  }
}
