@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.legendContainer {
  margin-bottom: $sds-spaces-l;

  .category {
    display: inline-block;
    margin-right: $sds-spaces-xl;

    svg {
      vertical-align: middle;
      width: 10px;
      height: 10px;

      circle {
        r: 5px;
      }
    }

    .label {
      margin-left: $sds-spaces-xxs;
    }
  }
}
