@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";
@import "../../sample_upload_flow";

.title {
  @include font-header-xxl;
}

.uploadMsg {
  @include font-body-m;
  margin-bottom: 40px;
  color: $sds-color-gray-500;
}

.reviewHeader {
  margin-bottom: $sds-spaces-xl;
  display: flex;

  .text {
    @include font-header-l;
  }

  .links {
    @include font-body-xs;
    display: flex;
    margin-left: $sds-spaces-m;
    align-items: center;
    margin-top: 5px;

    &.enabled {
      .link {
        color: $sds-color-primary-400;
        cursor: pointer;
      }
    }

    .divider {
      margin: 0 5px;
    }

    .link {
      color: $sds-color-gray-300;
      cursor: default;
    }
  }
}

.sectionContainer {
  margin-top: 50px;
}

.icon {
  margin-right: $sds-spaces-l;
}

.section {
  background-color: $sds-color-gray-100;
  padding: $sds-spaces-xl;
  display: flex;
  margin-bottom: $sds-spaces-xs;

  .name {
    @include font-header-m;
  }

  .text {
    flex: 1 1 0;
    min-width: 0;

    .header {
      display: flex;
      align-items: center;
    }
  }
}

.error {
  color: $sds-color-error-400;
}

.link {
  color: $sds-color-primary-400;
}

.successMessage,
.error,
.uploadMessage {
  @include font-body-xs;
  margin-top: 30px;
  margin-bottom: $sds-spaces-m;
}

.uploadMessage,
.successMessage {
  color: $sds-color-gray-500;
}

.loadingIcon {
  margin-right: 5px;
}

.sampleProcessingOption {
  margin-top: $sds-spaces-xl;
  align-items: left;
  justify-content: left;

  // Global because Checkbox does not use CSS modules
  :global(.label) {
    font-size: 12px;
    line-height: 20px;
    margin-left: $sds-spaces-m;
  }
}
