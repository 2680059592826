@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.iconAlert {
  width: $icon-l;
}

.icon {
  fill: $sds-color-primary-400;
  font-size: 20px;
}
