@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.pipelineVersionSelectContainer {
  padding-left: $sds-spaces-xxxs;
  margin-left: $sds-spaces-s;
  display: inline-flex;
  align-items: center;

  .pipelineVersion {
    @include font-body-xxxs;
    color: $sds-color-gray-500;
    margin-right: $sds-spaces-xxs;
  }

  .pipelineVersionDropdown {
    @include font-body-xxxs;
    color: $sds-color-gray-500;
    margin-right: $sds-spaces-s;
  }

  .dropdownTrigger {
    margin-right: 5px;
    margin-left: 3px;
  }
}
