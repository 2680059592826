@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.countryShowcaseContainer {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
  padding-top: 65px;
  border-top: 1px solid #d9d8d8;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  position: relative;
  align-items: center;

  section {
    max-height: 598px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;

    @media (max-width: 992px) {
      display: none;
    }
  }
}

// Country Scroller

.countryScrollItem {
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  cursor: pointer;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  width: 391px;

  &:hover {
    .countryScrollItemIndicator {
      background: #3867fa !important;
    }
  }

  &Indicator {
    width: 7.5px;
    min-height: calc(100% + 2px);
    flex-shrink: 0;
    background: #e4e4e4;
    position: absolute;
    top: 0;
    left: 0;
    &Active {
      background: #3867fa;
    }
  }

  &Description {
    display: flex;
    flex-direction: column;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 27.5px;

    h3 {
      @include font-header-l;
      margin-bottom: 0;
    }

    p {
      @include font-body-s;
      margin-top: 4px;
      line-height: 16.8px;
      color: #767676;
      max-width: 223px;
    }
  }

  &Cycle {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-left: auto;
    flex-shrink: 0;
    padding-top: 13px;
    padding-right: 60px;

    &Dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 5px;

      &.cycle1 {
        background: #3cb371;
      }

      &.cycle2 {
        background: #3867fa;
      }
    }

    &Text {
      font-size: 12px;
      font-weight: 400px;
      line-height: 26px;
      letter-spacing: 0.3px;
      color: #767676;
    }
  }
}

// End Country Scroller

// Country Showcase Window

.countryShowcaseWindow {
  padding: 39px 76px 54px 62px;
  border: 1px solid #dfdcdc;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  width: 67.4%;
  height: 700px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    width: 100%;
    height: auto;
    padding: 40px;
  }

  @media (max-width: 768px) {
    padding: 25px;
  }

  &TitleContainer {
    display: flex;
    padding-bottom: 27px;
    border-bottom: 1px solid #d9d8d8;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &CountryName {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.3px;
      color: #767676;
      text-transform: capitalize;
      flex-shrink: 0;
    }
  }

  &CountryCycleDot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 11px;

    &.cycle1 {
      background: #3cb371;
    }

    &.cycle2 {
      background: #3867fa;
    }
  }

  &ProjectTitle {
    @include font-header-xxl;

    @media (max-width: 768px) {
      @include font-header-l;
    }
  }

  &MapImage,
  &MapContainer svg {
    width: 100%;
    height: auto;
  }

  &MapContainer {
    flex-shrink: 0;
    max-width: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;

    @media (max-width: 768px) {
      align-self: center;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &DescriptionContainer {
    margin-top: 30px;
    margin-bottom: 12px;

    .countryShowcasePrincipalInvestigatorContainer {
      display: flex;
      align-items: center;

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .countryShowcasePrincipalInvestigatorName {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.3px;
      margin-bottom: 0;
    }

    .countryShowcasePrincipalInvestigatorTitle {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.3px;
    }
  }

  .countryShowcaseSummary {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #545454;
    margin-top: 22px;
  }

  .countryShowcaseCtaContainer {
    display: flex;
    margin-top: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .countryShowcaseCtaLink {
    display: inline-block;
    color: #3867fa !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 7px 36px;

    & + .countryShowcaseCtaLink {
      margin-left: 8px;

      @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    &:hover {
      color: #ffffff !important;
    }
  }

  .countryShowcaseNav {
    &Container {
      align-self: flex-end;
      margin-top: auto;

      @media (max-width: 992px) {
        width: 100%;
        height: auto;
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }

    &Button {
      cursor: pointer;

      &.disabled {
        cursor: default;
      }

      & + .countryShowcaseNavButton {
        margin-left: 10px;
      }
    }

    &Prev,
    &Next {
      circle,
      path {
        transition: 0.2s;
      }

      &:hover {
        circle {
          fill: #3867fa;
        }

        path {
          fill: #ffffff;
        }
      }
    }
  }
}
