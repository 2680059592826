@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";
:export {
  warningMedium: $sds-color-warning-400;
  warningDark: $sds-color-warning-600;
}

.scaleLabel {
  letter-spacing: 0.4px;
  font-size: 13px;
  font-weight: 600;
}

.warningIcon {
  // The filter is defined in dendrogram.js
  filter: url("#warning-medium");

  &:hover {
    cursor: default;
    filter: url("#warning-dark");
  }
}
