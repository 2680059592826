@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

// NOTE: Classname .toggle is repeated here as .toggle.toggle.toggle to increase
// specificity and always override semantic-ui.

// For unknown reasons, semantic-ui is adding the rule
//  [type=radio]:not(:checked)+label:after { transform: scale(0); }
// which has the effect of hiding the circle part of the toggle when off.
// The circle is always visible on the official example page at:
// https://react.semantic-ui.com/modules/checkbox/#types-toggle
.toggle.toggle.toggle label:after {
  transform: scale(1) !important;
  margin: $sds-spaces-xxs;
}

.toggle.toggle.toggle label:before {
  margin-top: $sds-spaces-xxs;
  margin-bottom: $sds-spaces-xxs;
  height: 28px;
  line-height: 28px;
  width: 68px;
}

.toggle.toggle.toggle label {
  @include font-body-xxs;
  line-height: 25px;
  padding-top: $sds-spaces-xs; // vertical align text
  min-height: 34px;
  min-width: 58px; // enough space for text
  z-index: 1; // place label text on top of the toggle
}

// Place text to the left
.toggle.toggle.toggle input:checked ~ label {
  padding-left: 17px;
  padding-right: 17px;
}

// Place text to the right
.toggle.toggle.toggle input:indeterminate ~ label {
  padding-left: 34px;
  color: $sds-color-gray-600 !important;
}

.toggle.toggle.toggle input:checked ~ label:before {
  background-color: $sds-color-gray-white !important; // override semantic-ui
  z-index: -1; // make sure the white background color is behind the text label
  border: $sds-borders-primary-400;
  padding-left: $sds-spaces-xxs;
}

.toggle.toggle.toggle input:indeterminate ~ label:before {
  background-color: $sds-color-gray-white !important; // override semantic-ui
  z-index: -1; // make sure the white background color is behind the text label
  border: $sds-borders-gray-300;
}

.toggle.toggle.toggle label:after {
  top: 5px; // vertically align the circle in the height of the container
  height: 18px;
  width: 18px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  transition-duration: 0.2s, 0.2s; // down from 0.3s
}

.toggle.toggle.toggle input:checked ~ label:after {
  background-color: $sds-color-primary-400;
  left: calc(
    68px - 9px - 18px
  ); // inner width of toggle minus inner padding minus width of circle
}

.toggle.toggle.toggle input:indeterminate ~ label:after {
  background: $sds-color-gray-200;
  left: 2px;
}
