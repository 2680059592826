@import "~@czi-sds/components/dist/variables";

.reportWrapper {
  position: relative;
  margin-top: $sds-spaces-xl;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.reportWrapperFiltersOpen {
  left: 0;
  width: 100%;
  @media screen and (max-width: 2100px) {
    --filtersOpenLeftShift: calc((2100px - 100vw) * (225) / (2100 - 1635));
    // Create a smooth transition between the two screen sizes
    // Between 1635px and 2100px, the left position should be a
    // number between 0px and 225px. When the screen is exactly 1635px,
    // this calculation will result in 225px, and when the screen is
    // exactly 2100px, this calculation will result in 0px.
    left: var(--filtersOpenLeftShift);

    // Shifting this container right makes the view/hide columns button not show.
    // We need to adjust the width by the same amount we shifted left.
    width: calc(100% - var(--filtersOpenLeftShift));
  }
  @media screen and (max-width: 1635px) {
    left: 225px;
    width: calc(100% - 225px);
  }
}

.reportWrapperFiltersClosed {
  left: 0;
  width: 100%;
  @media screen and (max-width: 1770px) {
    --filtersClosedLeftShift: calc((1770px - 100vw) * 75 / (1770 - 1635));
    // Create a smooth transition between the two screen sizes
    // Between 1635px and 1770px, the left position should be a
    // number between 0 and 75px. When the screen is exactly 1635px,
    // this calculation will result in 75px, and when the screen is
    // exactly 1770px, this calculation will result in 0px.
    left: var(--filtersClosedLeftShift);
    width: calc(100% - var(--filtersClosedLeftShift));
  }
  @media screen and (max-width: 1635px) {
    left: 75px;
    width: calc(100% - 75px);
  }
}

.dropdownWrapper {
  align-self: start;
  padding: $sds-spaces-xxl $sds-spaces-m 20px;
  border-bottom: 2px solid $sds-color-gray-300;
}

.tableWrapper {
  display: flex;
  flex-shrink: 1;
  flex-basis: auto;

  overflow-x: clip;
  div {
    div {
      // Note this has to be !important in order to override styles from React Virtuoso
      position: inherit !important;
    }
  }
}
