@import "../../themes/colors";
@import "~@czi-sds/components/dist/variables";

.taxon-info {
  &__modal {
    padding: 0 40px;
  }

  &__text {
    line-height: 24px;
  }

  &__histogram {
    width: 80%;
    height: 240px;
    margin: 0 auto;
  }

  &__subtitle {
    padding-top: 25px;
    padding-bottom: $sds-spaces-m;
    font-size: 14px;
    font-weight: 700;
  }

  &__links-section {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  &__links-list {
    min-width: 200px;
    float: left;
    list-style: none;
  }

  &__link {
    color: $sds-color-primary-400;
    line-height: 30px;
    cursor: pointer;
  }
}
