@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.loadingMessage {
  color: $sds-color-gray-500;
  display: flex;
  align-items: center;
  fill: $sds-color-gray-500;

  .loadingIcon {
    margin-right: $sds-spaces-xxs;
  }

  .text {
    @include font-body-xxs;
  }
}
