@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.warning {
  @include font-body-xxxs;
  color: $sds-color-warning-600;
  display: flex;

  .icon {
    flex: 0 0 auto;
    width: $icon-s;
    height: $icon-s;
    margin-right: 7px;
  }
}

// Only used for the human host age input.
.darkPlaceholder {
  :global(input::placeholder) {
    color: $sds-color-gray-black !important;
  }
}
