@import "../themes/colors";
@import "~@czi-sds/components/dist/variables";

.taxon-tree-vis {
  &__container {
    position: relative;
    margin-bottom: 15rem;
    overflow-x: scroll;

    .node-overlay {
      opacity: 0;
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }

  &__tooltip {
    background-color: $sds-color-gray-white;
    border: $sds-borders-gray-300;
    border-radius: $sds-corners-corner-m;
    box-shadow: $sds-drop-shadows-shadow-s;
    display: none;
    height: auto;
    min-width: 200px;
    padding: $sds-spaces-m;
    pointer-events: none;
    position: absolute;

    &.visible {
      display: block;
    }
  }
}
