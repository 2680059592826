@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.modal {
  padding: $sds-spaces-xxl;
  max-height: 70vh;
  left: auto;
  right: auto;
  overflow-y: auto;

  .closeIcon {
    position: absolute;
    right: $sds-spaces-xl;
    top: $sds-spaces-xl;
    cursor: pointer;
    width: $icon-l;
    height: $icon-l;
    fill: $sds-color-gray-400;

    &.s {
      width: $icon-s;
      height: $icon-s;
    }

    &.xl {
      width: $icon-xl;
      height: $icon-xl;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.narrow {
    width: 600px;
  }

  &.narrowest {
    width: 400px;
  }

  &.wide {
    width: 1080px;
  }

  &.tall {
    /* Overwrite semantic ui */
    max-height: 85vh !important;
  }

  &.fixedHeight {
    :global(.content) {
      height: calc(70vh - 80px);
    }
  }

  &.minimumHeight {
    min-height: 400px;
  }

  :global(.content) {
    /* Overwrite semantic ui */
    padding: 0 !important;
  }
}
