@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.tableHeader {
  padding: $sds-spaces-l $sds-spaces-xs;
  text-align: left;
  border-bottom: $sds-borders-gray-300;
}

.tableData {
  padding: $sds-spaces-l $sds-spaces-xs;
}
