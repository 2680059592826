@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.sign-in {
  margin-top: 0.4rem;
  margin-right: 0.75rem;
}

.alert-browser-support {
  color: $sds-color-gray-500;
  margin-right: 0.75rem;
  margin-top: 15px;

  .browser-name {
    font-weight: 700;
  }

  @media only screen and (max-width: 748px) {
    color: $sds-color-gray-500;
  }
}
