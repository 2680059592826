@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";
@import "~styles/themes/elements";

.counts {
  display: flex;
  align-items: center;
  flex-direction: column;

  .sampleCount {
    @include font-header-xs;
  }

  .analysesCounts {
    @include font-body-xxxs;
    margin-top: $sds-spaces-xxxs;
    color: $sds-color-gray-500;
  }
}

.loadingBackgroundAnimation {
  @include loadingAnimation;

  padding: $sds-spaces-xxs 0;
  background-clip: content-box;
  height: 20px;
}
