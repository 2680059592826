@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.AMRHeatmapView {
  .viewHeader {
    margin-top: 40px;
    margin-bottom: 40px;

    .controls {
      display: flex;

      .controlElement {
        margin-left: $sds-spaces-m;
      }
    }
  }

  .menu {
    background-color: $sds-color-gray-white;

    .filterRow {
      margin: 20px 0;
      height: $sds-spaces-xxl;

      .filterControl {
        float: left;
        margin-left: auto;
        padding: 0 $sds-spaces-default;
        width: 25%;
      }
    }
  }

  .deprecationWarning {
    width: auto;
    margin: $sds-spaces-l 0;
  }

  .loadingIndicator,
  .noDataMsg {
    margin: 50px;
    text-align: center;
    color: $sds-color-gray-500;
  }
}
