@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.taxaWithHitsDropdown {
  width: 300px;

  .optionsHeader {
    display: flex;
    padding: $sds-spaces-xs $sds-spaces-l;

    .header {
      @include font-header-xs;
      color: $sds-color-gray-500;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .option {
    display: flex;
    padding: $sds-spaces-xs $sds-spaces-l;

    .taxonName {
      @include font-body-xxs;
      flex-shrink: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .fill {
      flex: 1 1 0;
      min-width: 0;
    }

    .sampleCount {
      @include font-body-xxs;
      color: $sds-color-gray-500;
    }
  }
}
