@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.partnersSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1190px;
  margin-top: 80px;

  @media (min-width: 1001px) {
    margin-top: 120px;
    padding-top: 120px;
    border-top: 1px solid $gray-lightest;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-bottom: 120px;
  }

  &__wrap {
    margin-left: $sds-spaces-xl;
    margin-right: $sds-spaces-xl;
  }

  &__textBox {
    @media (min-width: 1001px) {
      max-width: 420px;
      margin-right: 50px;
    }

    h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: 0.3px;
      margin-bottom: 32px;

      @media (min-width: 1001px) {
        font-size: 34px;
        line-height: 46px;
      }
    }

    p {
      color: $gray-darkest;

      @media (min-width: 1001px) {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.3px;
      }
    }
  }

  &__logoGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    @media (max-width: 1000px) {
      width: 100%;
      margin-top: 24px;
    }
  }

  &__logoRow {
    &_top,
    &_bottom {
      display: flex;
      justify-content: center;
      column-gap: 20px;

      @media (max-width: 1000px) {
        width: 100%;
        align-self: center;
      }

      img {
        max-width: 226px;
        aspect-ratio: 226 / 134;

        @media (max-width: 1000px) {
          width: 46%;
        }
      }
    }

    &_bottom {
      @media (max-width: 1000px) {
        row-gap: 20px;
        flex-wrap: wrap;
      }
    }
  }
}
