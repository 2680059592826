@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.header {
  display: flex;

  &.toggleable {
    cursor: pointer;
  }

  &.center {
    align-items: center;
  }

  &.baseline {
    align-items: baseline;
  }

  .fill {
    flex: 1 1 0;
    min-width: 0;
  }
}

.toggleContainer {
  padding: 0 $sds-spaces-default;

  .toggleIcon {
    font-size: 22px;
    fill: $sds-color-gray-500;

    &.topRight {
      margin: -$sds-spaces-xxs 0 0 $sds-spaces-default;
      padding: 0;
    }

    &:hover {
      fill: $sds-color-gray-black;
    }
  }
}

.content {
  // prevent margin collapse, so that interior elements can set a bottom margin
  overflow: auto;
  margin-right: $sds-spaces-xl;

  &.bottomPadding {
    margin-bottom: 20px;
  }
}
