@import "~@czi-sds/components/dist/variables";

.rowCountHeader {
  height: 30px;
}

.rowCount {
  color: $sds-color-gray-500;
  display: flex;
  font: $sds-font-body-xxs-400-font;
}
