@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.heroEmailForm {
  max-width: 489px;
  width: 100%;
  border-radius: 999px;

  form {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  input,
  button {
    font-family: $sds-font-font-family;
    @include font-body-m;
    border: none;

    @media (max-width: 768px) {
      @include font-body-s;
      width: 100%;
      max-width: unset;
      padding: unset;
      text-align: center;
      padding: $sds-spaces-xl 0;
    }
  }

  input {
    border-radius: 999px 0 0 999px;
    width: 274px;
    padding: 13px 0 13px 38px;

    &::placeholder {
      color: $sds-color-gray-500;
    }

    @media (max-width: 768px) {
      border-radius: 999px;
      margin-bottom: 7px;
      width: 100%;
      padding: 13px;
    }
  }

  button {
    border-radius: 0 999px 999px 0;
    color: $sds-color-gray-white;
    background: $sds-color-primary-400;
    width: 215px;
    padding: 13px $sds-spaces-xxl 13px 30px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      border-radius: 999px;
      width: 100%;
    }

    span {
      margin-left: 1rem;
      border-left: none;
    }
    &.disabled {
      background: $sds-color-primary-500;
    }
  }
}
