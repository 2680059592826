@import "~styles/themes/colors";

.AMRHeatmapVis {
  .AMRHeatmapContainer {
    overflow-x: auto;
    margin-bottom: 300px;

    svg {
      display: block;
      margin: 0 auto;
    }
  }

  .tooltip {
    position: absolute;
    display: none;

    &.visible {
      display: block;
    }
  }
}
