@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.label {
  @include font-body-xs;

  .visualizationsLink {
    @include font-caps-xxxs;
    margin-top: $sds-spaces-s;
    cursor: pointer;
  }
}
