@import "~@czi-sds/components/dist/variables";

.title {
  font-size: 35px;
  line-height: 1.4em;
  font-weight: 600;
}

.groupName {
  line-height: 1.4em;
  font-weight: 600;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: $sds-spaces-m;
}

.metadataDictionary {
  margin-top: 35px;
  margin-bottom: 100px;
}

.required {
  font-weight: 700;
}

.hostGenome {
  margin-top: 20px;
}
