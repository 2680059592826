@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.pipelineVersionDropdown {
  @include font-body-xxxs;
  color: $sds-color-gray-500;
  margin-right: $sds-spaces-s;
}

.pipelineVersion {
  @include font-body-xxxs;
  color: $sds-color-gray-500;
  margin-right: $sds-spaces-xxs;
}

.dropdownTrigger {
  margin-right: 5px;
  margin-left: 3px;
}
