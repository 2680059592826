@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.field {
  padding: $sds-spaces-m 0;
  display: flex;
  align-items: center;

  &:nth-child(even) {
    background-color: $sds-color-gray-100;
  }

  .label {
    font-weight: $font-weight-semibold;
    color: $sds-color-gray-600;
    width: 160px;
    flex-shrink: 0;
    margin-right: $sds-spaces-xl;
    margin-left: $sds-spaces-m;
  }
}
