@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.privacyPolicy,
.termsOfUse,
.faqPage {
  margin-top: 90px;
  margin-bottom: 90px;

  h1 {
    @include font-header-xxl;
    margin-bottom: 0;
  }

  h2 {
    @include font-header-xl;
    margin-top: 40px;
    position: relative;

    .number {
      position: absolute;
      right: 100%;
      margin-right: $sds-spaces-s;
    }
  }

  h3 {
    @include font-header-m;
    margin-top: 30px;
    position: relative;

    .number {
      position: absolute;
      right: 100%;
      margin-right: $sds-spaces-s;
    }
  }

  h4.subtitle {
    @include font-body-xxs;
    margin-top: 5px;
    color: $sds-color-gray-500;
  }

  .title {
    margin-bottom: 50px;
  }

  p {
    @include font-body-s;

    &.large {
      @include font-body-m;
    }

    &.last {
      margin-bottom: 50px;
    }
  }

  a {
    // Override _header.scss
    color: $sds-color-primary-400 !important;
  }

  ul {
    margin-left: 40px;

    li {
      @include font-body-s;
      list-style-type: disc;
      margin-bottom: $sds-spaces-m;

      .listItemLabel {
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }

  .cookieSettings {
    color: $sds-color-primary-400;

    &:hover {
      cursor: pointer;
    }
  }

  .nestedList {
    margin: 20px 0 20px 30px;
  }

  .underlineHeader {
    @include font-body-s;
    margin-top: 30px;
    text-decoration: underline;
  }

  .italics {
    font-style: italic;
  }

  .nestedList {
    margin-left: 40px;
  }

  b {
    font-weight: $font-weight-semibold;
  }

  .privacySummaryTable {
    margin: 25px 0px;
    border-style: solid;
    border-width: 1px;
    width: 840px;
    height: 2458px;

    td {
      padding: $sds-spaces-m 15px;
      border: $sds-borders-gray-300;
      text-align: left;
    }

    .topRow {
      @include font-header-s;
      background-color: $sds-color-gray-200;

      td {
        text-align: center;
      }
    }

    .sectionRow {
      @include font-header-s;
      background-color: $sds-color-gray-100;

      td {
        text-align: center;
      }
    }

    .contentRow {
      td {
        @include font-body-s;
        vertical-align: top;
      }
    }
  }
}

.faqPage .question {
  border-bottom: 1px solid $gray-light;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 $sds-spaces-m;
    min-height: 50px;
    display: flex;
    align-items: center;

    &:hover {
      color: $sds-color-primary-400;
    }

    &.multiLine {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  p {
    margin: 0px 50px $sds-spaces-m $sds-spaces-m;
  }

  .innerListItem {
    margin-top: $sds-spaces-m;
    margin-left: 20px;
  }
}
