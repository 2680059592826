@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.samplesHeatmapLegend {
  margin-top: -$sds-spaces-xs;
  padding-top: $sds-spaces-l;
  padding-bottom: $sds-spaces-l;
  width: 125px;
  height: 20px;
}
