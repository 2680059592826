@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.ctaButton {
  display: inline-block;
  color: $sds-color-primary-400 !important;
  font-family: $sds-font-font-family;
  border: $sds-borders-primary-400;
  text-decoration: none;
  padding: 7px $sds-spaces-l;
  border-radius: 999px;
  text-align: center;
  @include font-body-xs;
  font-weight: $font-weight-semibold;

  &:hover {
    background-color: lighten($sds-color-primary-400, 2.5);
    border-color: lighten($sds-color-primary-400, 2.5);
    color: white !important;
    cursor: pointer;
  }
}
