// Uncomment or remove
@import "~@czi-sds/components/dist/variables";

.filtersContainer {
  max-width: 200px;
  z-index: 2;
  position: absolute;
  top: 155px; // height of the page headers
  left: 0px;
  width: fit-content;
  height: 100%;
}

.filtersContent {
  height: 100%;
  padding: $sds-spaces-l;
  border-right: 1px solid $sds-color-gray-200;
}

.filtersContentClosed {
  width: 70px;
}

.filtersContentOpen {
  width: 200px;
}

.hideFilters {
  display: none;
}

.filters {
  margin-top: $sds-spaces-l;
}

.sectionTitle {
  font: $sds-font-header-s-600-font;
  color: $sds-color-gray-black;
  margin-bottom: $sds-spaces-s;
  margin-left: $sds-spaces-xs;
}
