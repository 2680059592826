@import "../../themes/colors";
@import "~@czi-sds/components/dist/variables";

.idseq-ui.ui.menu {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .active.item:not(.disabled) {
    background-color: $sds-color-primary-400;
    // TODO for removing importants from _header.scss
    color: $sds-color-gray-white !important;
  }
}
