@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.termsAgreement {
  margin-top: $sds-spaces-xl;
  margin-bottom: $sds-spaces-xl;
  color: $sds-color-gray-600;

  // Global because Checkbox does not use CSS modules
  :global(.label) {
    font-size: 12px;
    line-height: 20px;
    margin-left: $sds-spaces-m;

    span {
      cursor: default;

      a {
        color: $sds-color-primary-400 !important;
      }
    }
  }
}
