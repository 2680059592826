@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.ctaContainer {
  margin-top: 70px;
  display: flex;
  max-width: 1000px;
  margin: 70px auto 0 auto;
  justify-content: center;
  padding: 0 5%;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px auto 0 auto;
    padding: 0 5%;
  }

  h2 {
    font-size: 32px;
    line-height: 44px;

    @media (max-width: 992px) {
      font-size: 28px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .btnLink {
    width: 172px;
    height: 34px;
    color: #ffffff !important;
    margin-top: 27px;
    margin-bottom: 60px;

    @media (max-width: 992px) {
      margin: 30px auto 60px auto;
    }
  }

  img {
    width: 100%;
    max-width: 428px;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
  }
}

.ctaText {
  width: 100%;
  max-width: 420px;
}
