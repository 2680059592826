@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.content {
  @include font-body-xxs;
  padding: 30px;
}

.accordion {
  border-bottom: $sds-borders-gray-300;
}

.accordionContent {
  margin-bottom: 20px;
}

.stepName {
  @include font-header-l;
  margin-bottom: 16px;
  margin-right: 60px;
  word-break: break-all;
}

.statusBox {
  padding: $sds-spaces-l;
  display: flex;
  border-radius: $sds-corners-corner-m;
  margin-bottom: $sds-spaces-l;

  .statusIcon {
    height: 20px;
    width: 20px;
    margin-top: $sds-spaces-xs;
    flex-shrink: 0;
  }

  .statusText {
    margin-left: $sds-spaces-m;
  }

  .statusTitle {
    @include font-header-s;
  }

  .statusDescription {
    @include font-body-xxs;
  }

  a {
    // Use border-bottom instead of text-decoration: underline to give space under text
    border-bottom: 1px dashed $sds-color-gray-400;
  }

  &.inProgress {
    background-color: $sds-color-primary-200;

    .statusIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $sds-color-primary-400;
      font-size: 20px;
    }
  }

  &.pipelineErrored {
    background-color: $sds-color-error-100;

    .statusIcon {
      fill: $sds-color-error-400;
    }
  }

  &.userErrored {
    background-color: $sds-color-warning-100;
    .statusIcon {
      fill: $sds-color-warning-400;
    }
  }

  &.finished {
    background-color: $sds-color-success-100;
    .statusIcon {
      fill: $sds-color-success-400;
    }
  }
}

.title {
  @include font-caps-xxs;
  height: 40px;
}

.description {
  p,
  ol,
  ul,
  li {
    @include font-body-s;
  }

  li {
    margin-bottom: 1em;
  }

  pre {
    padding: 12px 12px 18px;
    background-color: $sds-color-gray-100;
    margin-left: 15px;
  }

  code {
    @include font-code;
    color: $sds-color-gray-600;
  }

  a {
    // Override color: inherit in _header.scss.
    color: $sds-color-primary-400 !important;
  }
}

.fileLink {
  color: $sds-color-primary-400;
  cursor: pointer;
  margin-bottom: $sds-spaces-s;
}

.disabledFileLink {
  color: $sds-color-gray-500;
  cursor: default;
  margin-bottom: $sds-spaces-s;
}

.fileGroup {
  margin-bottom: 18px;

  .fileLink,
  .disabledFileLink {
    margin-left: 25px;
  }

  .fileGroupHeader {
    @include font-header-xs;
    margin-bottom: $sds-spaces-s;
  }
}

.resourcesContainer {
  display: flex;
  flex-wrap: wrap;

  span {
    width: 50%;
    padding-right: 12px;
    margin-bottom: $sds-spaces-s;
  }

  a {
    // Override color: inherit in _header.scss.
    color: $sds-color-primary-400 !important;
  }
}
