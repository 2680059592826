@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.blastContigsModal {
  display: flex;
  flex-direction: column;

  .header {
    @include font-header-xl;
    text-transform: uppercase;

    color: $sds-color-gray-black;
  }

  .taxonName {
    @include font-body-xxs;
    color: $sds-color-gray-500;
  }

  .contigSelection {
    &.spacing {
      margin-top: $sds-spaces-xl;
    }

    .title {
      font: $sds-font-header-m-600-font;
    }

    .instructions {
      font: $sds-font-body-s-400-font;
      margin-top: $sds-spaces-s;
    }
  }

  .table {
    flex-direction: column;

    flex-grow: 1;
    margin-top: $sds-spaces-l;
  }

  .longContigNotification {
    margin-top: $sds-spaces-xl;
  }

  .actions {
    margin-top: $sds-spaces-xxl;
    display: flex;

    .item {
      &:not(:first-child) {
        margin-left: $space-xs;
      }

      &:not(:last-child) {
        margin-right: $space-xs;
      }
    }
  }
}
