@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.otherCheckboxSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  // Clear SDS InputCheckbox top/bottom/left padding
  :global(.MuiButtonBase-root.MuiCheckbox-root) {
    padding: 0 9px 0 0;
  }
}
.otherInput {
  padding-left: $sds-spaces-m;
  margin-bottom: 0px;
}
.otherLabel {
  @include font-body-xs;
  vertical-align: middle;
}
