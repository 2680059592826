@import "~styles/themes/elements";
@import "~styles/themes/colors";
@import "~styles/themes/typography";

.maintenance {
  height: calc(100vh - #{$header-total-height});

  .imgMicrobe {
    width: $img-m;
    height: $img-m;
  }
}
