@import "~@czi-sds/components/dist/variables";

.wrapper {
  background-color: $sds-color-gray-white;
  color: $sds-color-gray-500;
  &:hover {
    color: $sds-color-gray-black;
    svg {
      fill: $sds-color-gray-black;
    }
  }
  span {
    font: $sds-font-header-s-600-font;
    white-space: nowrap;
    overflow: hidden;
  }
}

.active {
  color: $sds-color-primary-400;
  &:hover {
    color: $sds-color-primary-500;
    svg {
      fill: $sds-color-primary-500;
    }
  }
}
