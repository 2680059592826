@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";
@import "~styles/core";

.subtitle {
  padding-top: 25px;
  padding-bottom: $sds-spaces-m;
  font-size: 14px;
  font-weight: 700;
}

.text {
  line-height: 24px;
}

.collapsed {
  max-height: 120px;
  overflow: hidden;
}

.expandLink {
  color: $sds-color-primary-400;
  margin-top: $sds-spaces-m;
  cursor: pointer;
  @extend .noStyleButton;
}
