@import "~styles/themes/elements";

* {
  @include scrollable();
}

html {
  scroll-behavior: smooth;
}

.noStyle {
  all: unset;
}

.noStyleButton {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: inherit;
}
