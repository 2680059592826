@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.nextcladeConfirmation {
  .logo {
    margin-bottom: $sds-spaces-l;
    width: 184px;
  }

  .title {
    @include font-header-xl;
    color: $sds-color-gray-black;
  }

  .text {
    @include font-body-s;
    margin-top: $sds-spaces-s;
    margin-bottom: $sds-spaces-xl;
    color: $sds-color-gray-600;
  }

  .actions {
    margin-top: $sds-spaces-m;
    display: flex;

    .item {
      &:not(:first-child) {
        margin-left: $sds-spaces-xs;
      }

      &:not(:last-child) {
        margin-right: $sds-spaces-xs;
      }
    }

    .loadingIcon {
      fill: $sds-color-gray-500;
      width: $icon-l;
      height: $icon-l;
      vertical-align: middle;
      margin-right: $sds-spaces-xxs;
    }

    .loading {
      @include font-body-s;
      color: $sds-color-gray-500;
      vertical-align: middle;
    }
  }
}
