@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.previewContainer {
  white-space: nowrap;

  .tagContainer {
    @include font-body-xxs;
    color: $sds-color-gray-black;
    font-style: normal;
    margin-left: $sds-spaces-s;

    .count {
      margin-left: $sds-spaces-xxxs;
    }
  }
}
