@import "../../themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.data-table {
  max-height: 300px;

  thead {
    // remove scrollbar width
    display: table;
    width: calc(100% - 10px);
    border-bottom: 2px solid $sds-color-gray-200;
    text-align: left;

    th {
      line-height: normal;
      padding: 0;
      padding-right: $sds-spaces-m;
      height: 40px;

      &:first-child {
        padding-left: 15px;
      }
    }
  }

  thead tr,
  tbody tr {
    table-layout: fixed;
    display: table;
    width: 100%;
    border-collapse: collapse;
  }

  tbody {
    display: block;
    overflow-y: scroll;

    td {
      border-bottom: 1px solid $gray-lightest;
      word-wrap: break-word;
      line-height: normal;
      padding: 5px $sds-spaces-m 5px 0;
      height: 40px;

      &:first-child {
        padding-left: 15px;
      }
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $sds-color-gray-500;
    }
  }

  &__header {
    @include font-header-s;
    color: $sds-color-gray-black;

    &.column-reserved-selectable {
      width: 30px;
    }
  }

  &__data {
    &.column-reserved-selectable {
      width: 30px;
    }
  }

  &.selectable {
    // If the table has checkboxes on the first column, don't pad the first column.
    th:first-child {
      padding-left: 0;
    }

    td:first-child {
      padding-left: 0;
    }
  }

  &.striped {
    // Undo materialize striping
    tr:nth-child(odd) {
      background-color: transparent;
    }

    tr:nth-child(even) {
      background-color: $sds-color-gray-100;
    }

    td {
      border-bottom-color: transparent;
    }
  }
}
