@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

$tooltip-border-width: 1px;
$tooltip-border-color: $sds-color-gray-300;
$tooltip-tip-size: 8px;

.mapTooltip {
  z-index: 1;

  // Global to override some MapboxGL styling
  :global(.mapboxgl-popup-content) {
    padding: 0;
    border: $tooltip-border-width solid $tooltip-border-color;
    border-radius: $sds-corners-corner-m;
    border-bottom: none;
    box-shadow: $sds-drop-shadows-shadow-m;

    // Make a CSS tip triangle https://css-tricks.com/snippets/css/css-triangle/
    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
    }

    &:after {
      border-top-color: $sds-color-gray-white;
      border-width: $tooltip-tip-size;
      margin-left: -$tooltip-tip-size;
    }

    &:before {
      border-top-color: $tooltip-border-color;
      border-width: $tooltip-tip-size + $tooltip-border-width;
      margin-left: -($tooltip-tip-size + $tooltip-border-width);
    }

    .content {
      padding: 12px;

      .title {
        @include font-header-xs;
        cursor: pointer;

        &.hoverable {
          &:hover {
            color: $sds-color-primary-400;
          }
        }
      }

      .body {
        @include font-body-xs;
      }
    }
  }
}
