@import "~styles/themes/elements";
@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.listContainer {
  margin: $sds-spaces-m 0;

  background-color: $sds-color-primary-200;

  &.warning {
    background-color: $sds-color-warning-100;
  }
}

.messageContainer {
  @include font-body-xs;

  margin: $sds-spaces-m 0;

  strong {
    font-weight: $font-weight-bold;
  }
}

.messageLine {
  @include font-body-xs;

  margin: 0 $sds-spaces-m;
  padding: $sds-spaces-m;
  // .messageContainer margin + .icon margin + .icon width (see notification.scss)
  margin: 0 calc(#{$sds-spaces-m} + #{$sds-spaces-l} + 20px);

  &:nth-child(odd) {
    background-color: $sds-color-info-100-overlay;
    &.warning {
      background-color: $sds-color-warning-100-overlay;
    }
  }

  &:nth-child(even) {
    background-color: $sds-color-primary-200;
    &.warning {
      background-color: $sds-color-warning-100;
    }
  }

  &:last-child {
    margin-bottom: $sds-spaces-l;
  }
}
