@import "themes/colors";
@import "~@czi-sds/components/dist/variables";

.outer-container {
  margin-top: 10rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;
  padding: 3rem;
  box-shadow: $sds-drop-shadows-shadow-m;
  border-radius: $sds-corners-corner-m;
  border: 0.1rem solid $sds-color-gray-200;
}

.new-pass-field {
  margin-top: 30px;
}

input:focus {
  border-bottom-color: $sds-color-primary-400 !important;
}

.terms {
  margin-bottom: 1rem;
}

.terms-link {
  color: $sds-color-primary-400 !important;
}

.submit-btn {
  margin-top: 2rem;
  text-align: center;
}
