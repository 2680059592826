@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.emptyStateBanner {
  height: 380px;

  .iconClassName {
    svg {
      fill: $sds-color-primary-400;
      height: 150px;
      width: 150px;
    }
  }

  .content {
    margin-right: $sds-spaces-xxl;
  }
}

.messageClassName {
  @include font-body-m;
}

.titleClassName {
  @include font-header-xl;
}
