@import "~@czi-sds/components/dist/variables";

:global(.ui):global(.dropdown).multipleNestedDropdown {
  :global(.item) {
    /* override semantic-ui */
    padding: $sds-spaces-xs $sds-spaces-l $sds-spaces-xs 9px !important;
  }

  &:global(.active) {
    .dropdownTrigger {
      border: $sds-borders-primary-400;
    }
  }

  .multipleNestedDropdownButtons {
    /* Override row css */
    text-align: center;
    padding: 10px;

    .button {
      margin-left: $sds-spaces-m;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
