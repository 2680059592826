@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.checkbox {
  @include font-body-xs;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: $sds-spaces-l;
  // Clear InputCheckbox top/bottom/left padding
  :global(.MuiButtonBase-root.MuiCheckbox-root) {
    padding: 0 9px 0 0;
  }
}
