@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.expandLink {
  @include font-body-s;
  color: $sds-color-primary-400;
  margin: $sds-spaces-m 0 0 0;
  background-color: transparent;
  border: none;
  padding: 0;
}

.textSynonym {
  @include font-header-xs;
  color: $sds-color-gray-500;
}

.text {
  @include font-body-s;
}
