@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.chartsContainer {
  display: flex;
  flex-flow: row wrap;

  .fullPageChart {
    color: $sds-color-gray-black;
    padding-right: $sds-spaces-xl;
    padding-bottom: $sds-spaces-xl;
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;

    .title {
      @include font-header-l;
      margin-bottom: $sds-spaces-l;
      margin-top: $sds-spaces-l;
      height: max-content;
    }

    .histogramContainer {
      padding: $sds-spaces-xl;
      border: $sds-borders-gray-300;
      border-radius: $sds-corners-corner-m;
      width: 100%;
      min-height: calc(#{$img-l} * 2.5);
      min-width: calc(#{$img-l} * 2.5);
      box-shadow: 0 2px 4px 0 $sds-color-gray-300;
      display: flex;
      flex-direction: column;
      position: relative;

      .toggleContainer {
        position: absolute;
        top: $space-l;
        right: $space-xl;
      }

      .subtitle {
        @include font-header-s;
        margin-bottom: $sds-spaces-l;

        .infoIcon {
          margin: $sds-spaces-xxxs $sds-spaces-xxs 0 $sds-spaces-xxs;
          vertical-align: text-top;
        }
      }

      .legend {
        @include font-body-xxs;
        margin-bottom: $sds-spaces-l;
      }

      .stackedBarChart {
        margin: 0px;
      }

      // see discovery_view.scss
      .noDataBannerFlexContainer {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        // The following before and after attributes center
        // the div on the available space, while keeping a max-height.
        &::before {
          content: "";
          flex: 1 0 auto;
          max-height: 250px;
        }

        &::after {
          content: "";
          flex: 1 0 auto;
        }

        .noDataBannerContainer {
          flex: 0 0 auto;
        }
      }
    }
  }
}

.inlineLegend {
  margin: 0px;
}

.summaryTooltip {
  transform: translateY(-100%);
  z-index: 100;
}
