@import "~styles/themes/typography";
@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.wikiLicense {
  @include font-body-xxs;

  padding-top: $sds-spaces-m;
  color: $sds-color-gray-500;
}

.wikiLink {
  color: $sds-color-gray-black !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
