@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.editableInput {
  display: flex;
  align-items: center;
  cursor: pointer;

  .editableText {
    border-bottom: 1px dashed $sds-color-gray-500;
  }

  .editIcon {
    flex: 1 0 auto;
    color: $sds-color-gray-500;
    margin-left: $sds-spaces-m;

    &:hover {
      color: $sds-color-primary-400;
    }
  }
}

.alertContainer {
  @include font-body-xxxs;
  display: flex;
  margin-top: $sds-spaces-xxs;

  &.warning {
    color: $sds-color-warning-600;
  }

  &.error {
    color: $sds-color-error-600;
  }

  .alertIcon {
    width: $icon-s;
    height: $icon-s;
    margin-right: $sds-spaces-xxs;
    // Vertical-align the svg element with the text.
    transform: translateY(1px);
  }
}
