@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.errorModal {
  .accordionHeader {
    @include font-header-s;
  }

  .accordionNotification {
    margin-bottom: $sds-spaces-xl;
  }

  .notification {
    margin: 0px;
  }

  .textContainer {
    margin-bottom: $sds-spaces-xl;

    .title {
      @include font-header-xl;
      display: flex;
      align-items: flex-start;

      .text {
        margin-right: $sds-spaces-l;
      }

      .imgMatrix {
        fill: $sds-color-primary-400;
        width: $img-s;
        flex-shrink: 0;
      }
    }

    .message {
      @include font-body-s;
      margin-top: $sds-spaces-s;
      color: $sds-color-gray-600;
    }
  }

  .actions {
    margin-top: $sds-spaces-m;
    display: flex;
  }
}
