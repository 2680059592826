@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.sampleMessage {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  .textContainer {
    margin-top: $sds-spaces-s;
    margin-right: $sds-spaces-xl;
    width: 330px;

    .reportStatus {
      display: inline-flex;
      align-items: center;
      background-color: $sds-color-error-200;
      border-radius: $sds-corners-corner-l;
      padding: $sds-spaces-xxs $sds-spaces-s;

      .icon {
        width: $icon-l;
      }

      .text {
        @include font-caps-xxxs;
        margin-left: $sds-spaces-s;
        margin-top: $sds-spaces-xxxs;
      }

      &.warning {
        background-color: $sds-color-warning-100;
        color: $sds-color-warning-400;
      }

      &.error {
        background-color: $sds-color-error-100;
        color: $sds-color-error-400;
      }

      &.success {
        background-color: $sds-color-success-200;
        color: $sds-color-success-600;

        .icon {
          fill: $sds-color-success-400;
          font-size: 20px;
        }
      }

      &.inProgress {
        background-color: $sds-color-primary-200;
        color: $sds-color-primary-400;

        .icon {
          fill: $sds-color-primary-400;
          font-size: 20px;
        }
      }

      &.info {
        background-color: $sds-color-primary-200;
        color: $sds-color-primary-400;
      }
    }

    .message {
      @include font-header-m;
      margin-bottom: $sds-spaces-s;
      margin-top: $sds-spaces-s;
      margin-left: $sds-spaces-xxxs;
    }

    .subtitle {
      @include font-body-xxs;
      margin-left: $sds-spaces-xxxs;
      margin-bottom: $sds-spaces-s;
    }

    .actionLink {
      @include font-header-xxs;
      color: $sds-color-primary-400 !important;
      margin-top: $sds-spaces-m;
      margin-left: $sds-spaces-xxxs;

      .rightArrow {
        font-size: 8px;
        margin-left: $sds-spaces-xxs;
      }
    }
  }

  .imgMicrobe {
    width: $img-m;
    height: $img-m;
    z-index: -1;
  }
}
