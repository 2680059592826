@import "themes/typography";
@import "themes/colors";
@import "~@czi-sds/components/dist/variables";

.results-folder {
  .header {
    margin: 25px 75px;
    .title {
      @include font-header-xxl;
      color: $sds-color-primary-400;
      .path {
        margin: 20px;
        color: $sds-color-gray-black;
        font-weight: $font-weight-thin;
      }
      .back {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    th {
      color: $sds-color-gray-600;
    }
  }
  .file-link {
    cursor: pointer;
    color: $sds-color-gray-600;
    &:hover {
      color: $sds-color-primary-400;
    }
  }
  tr.disabled-file-link {
    color: $sds-color-gray-500;
  }
  i.fa-file {
    margin-right: 15px;
  }
  .size-tag {
    color: $sds-color-gray-300;
  }
}
