@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.ul {
  list-style-type: disc;
  padding-left: 0;
  margin-top: $sds-spaces-l;

  .li {
    list-style: disc outside none !important; // override browser-default's list-style-type: none
  }
  .xxsmallSpacing {
    margin-bottom: $sds-spaces-xxs;
  }

  .xsmallSpacing {
    margin-bottom: $sds-spaces-xs;
  }

  .smallSpacing {
    margin-bottom: $sds-spaces-s;
  }
}

.ol {
  padding-left: 0;
  margin-top: $sds-spaces-l;

  .xxsmallSpacing {
    margin-bottom: $sds-spaces-xxs;
  }

  .xsmallSpacing {
    margin-bottom: $sds-spaces-xs;
  }

  .smallSpacing {
    margin-bottom: $sds-spaces-s;
  }
}
