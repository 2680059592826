@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.listNotification {
  .accordion {
    background-color: $sds-color-gray-200;
    border-radius: $sds-corners-corner-m;
    margin-top: $sds-spaces-m;

    .title {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      padding: $sds-spaces-m;
    }

    .content {
      max-height: 300px;
      max-width: 340px;
      overflow: auto;

      .listItem {
        line-height: 22px;
        padding: $sds-spaces-m;

        &:nth-child(odd) {
          background-color: $sds-color-gray-200;
        }
      }
    }
  }

  .accordionIcon {
    font-size: 20px;
  }
}
