@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.popupText {
  @include font-body-xxs;
  text-align: center;

  .popupSubtitle {
    color: $sds-color-gray-500;
  }
}
