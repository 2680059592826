@import "~styles/themes/colors";
@import "~@czi-sds/components/dist/variables";

.dropdownLabel {
  /* Override semantic ui */
  background-color: $sds-color-gray-100 !important;
  color: $sds-color-primary-400 !important;
  font-weight: 600 !important;
  float: right;
  font-size: 12px;

  &.disabled {
    color: $sds-color-gray-500 !important;
  }
}
