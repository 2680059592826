@import "~styles/themes/colors";
@import "~styles/themes/elements";
@import "~styles/themes/typography";
@import "~@czi-sds/components/dist/variables";

.rectangle {
  @include font-header-s;
  background-color: $sds-color-gray-white;
  border-radius: $sds-corners-corner-m;
  box-shadow: $sds-drop-shadows-shadow-m;
  cursor: pointer;
  padding: $sds-spaces-xxxs 7px;
  text-align: center;
  // Center horizontal/vertical midpoints
  transform: translate(-50%, -50%);

  &.hoverable {
    &:hover {
      background-color: $sds-color-primary-500;
      color: $sds-color-gray-white;
    }
  }

  &.active {
    background-color: $sds-color-primary-500;
    color: $sds-color-gray-white;
  }
}
