@import "~@czi-sds/components/dist/variables";

.main {
  padding-bottom: $sds-spaces-xl;
}
.titleSection {
  padding-bottom: $sds-spaces-m;
}
.titleMainText {
  font: $sds-font-header-s-600-font;
  color: $sds-color-gray-black;
}
.titleSubText {
  font: $sds-font-body-s-400-font;
  color: $sds-color-gray-black;
}
.titleOptionalText {
  font: $sds-font-body-s-400-font;
  color: $sds-color-gray-400;
  padding-left: $sds-spaces-xs;
}
