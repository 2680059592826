@import "~styles/themes/colors";
@import "~styles/themes/typography";
@import "~styles/themes/elements";
@import "~@czi-sds/components/dist/variables";

.statusLabel {
  @include font-caps-xxxxs;
  line-height: 9px;
  border-radius: $sds-corners-corner-l;
  margin-left: $sds-spaces-xs;
  padding: $sds-spaces-xxs $sds-spaces-xs;

  &.default {
    background-color: $sds-color-gray-200;
    color: $sds-color-gray-500;
  }

  &.success {
    background-color: $sds-color-success-100;
    color: $sds-color-success-400;
  }

  &.error {
    background-color: $sds-color-error-100;
    color: $sds-color-error-400;
  }

  &.info {
    background-color: $sds-color-primary-200;
    color: $sds-color-primary-400;
  }

  &.warning {
    background-color: $sds-color-warning-100;
    color: $sds-color-warning-400;
  }

  &.inline {
    display: inline-block;
  }

  &.beta {
    background-color: $sds-color-beta-200;
    color: $sds-color-beta-600;
  }
}
